import React, { useContext, useEffect } from "react"
import Datetime from "app/shared/dateutil"
import useSperrDatumStore from "app/shared/sperrdatum/state"
import { useAuthUser } from "app/shared/authentication/hooks"
import { createPortal } from "react-dom"
import Icon from "app/shared/ui/icons/icons"
import { Tip } from "app/shared/ui/tooltip"
import { isNil } from "lodash"

export const Sperrdatum = ({ int_id, datum }) => {
  const sperrDatum = useSperrDatum({ int_id, datum })
  let color = "#ccc"
  let icon = "lock"
  if (sperrDatum.datum < datum) {
    return null
  }
  if (sperrDatum.noSperre) {
    color = "#ccc"
    icon = "lock-open"
  }

  return (
    <>
      {createPortal(
        <>
          <Tip target={".sperrdatum"} position={"left"}>
            Sperrdatum aktiv, Änderungen sind ab dem{" "}
            <b css={{ color: "#f33" }}>
              {Datetime.fromISO(sperrDatum.datum).formatDateShort()}
            </b>{" "}
            möglich !
          </Tip>
          <div
            css={{
              marginRight: "5px",
              lineHeight: "32px",
              height: "30px",
              textShadow: "1px 1px 1px #000"
            }}
            className={"sperrdatum"}
          >
            <Icon
              iconType="fa"
              icon={icon}
              css={{
                color: color,
                marginLeft: "20px",
                marginTop: "5px"
              }}
            />
          </div>
        </>,
        document.getElementById("sperrdatum")
      )}
    </>
  )
}

export default Sperrdatum
export const LockableContext = React.createContext({ Datum: null, IntID: null })
export const ReadonlyContext = React.createContext(false)

export const SanitizePermission = (permission, requiredPermission = 2) => {
  if (permission < 0) {
    return undefined
  }
  return permission >= requiredPermission
}

export const SanitizePermissionIgnoreUnset = (
  permission,
  requiredPermission = 2
) => {
  if (isNil(permission)) {
    return requiredPermission
  }
  if (permission < 0) {
    return requiredPermission
  }
  return permission >= requiredPermission
}

export const ReadOnlyWithParentContext = ({
  value = undefined,
  int_id = undefined,
  datum = "2999-01-01T00:00:00Z",
  children
}) => {
  const ro = useReadonly({ readOnly: value, datum, int_id })
  const v = value === true ? value : ro
  return (
    <ReadonlyContext.Provider value={v}>{children}</ReadonlyContext.Provider>
  )
}

export const useReadonly = ({
  readOnly = false,
  datum = "2999-01-01T00:00:00Z",
  int_id = null
}) => {
  const readonlyC = useContext(ReadonlyContext)
  const { Datum, IntID } = useContext(LockableContext)
  const { sperre } = useSperrDatum({
    datum: datum || Datum,
    int_id: int_id || IntID
  })
  if (readOnly || readonlyC) {
    return true
  }
  return sperre
}

export const useSperrDatum = ({ int_id, datum }) => {
  const actions = useSperrDatumActions()
  const user = useAuthUser()
  useEffect(() => {
    actions.LoadSperre(int_id, datum)
  }, [int_id, datum, user.user_id])
  useEffect(() => {
    actions.LoadNoSperre(int_id, user.user_id)
  }, [user.user_id, int_id])
  const sperrDatum = useSperrDatumStore((state) => state.sperre[int_id])
  const noSperre = useSperrDatumStore((state) => state.nosperre[int_id])
  if (noSperre) {
    return { sperre: false, noSperre: true, datum: sperrDatum }
  }
  return { sperre: sperrDatum >= datum, noSperre: false, datum: sperrDatum }
}

export const useSperrDatumActions = () => {
  return useSperrDatumStore((state) => state.actions)
}
