export const arrayToObject = (array, keyField) => {
  if (!array) {
    return []
  }
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {})
}

export const arrayToObjectFn = (array, keyFieldFn, objectFn = (o) => o) => {
  if (!array) {
    return []
  }
  return array.reduce((obj, item) => {
    obj[keyFieldFn(item)] = objectFn(item)
    return obj
  }, {})
}

export const arrayToArrayObjectFn = (array, keyFieldFn) => {
  if (!array) {
    return {}
  }
  return array.reduce((obj, item) => {
    const keyField = keyFieldFn(item)
    obj[keyField] = [...(obj[keyField] || []), item]
    return obj
  }, {})
}

export const removeNullUndefinedWithReduce = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] =
        typeof value === "object" ? removeNullUndefinedWithReduce(value) : value
    }
    return acc
  }, {})
}
