import { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { App } from "./app.js"

import "./init.js"

const container = document.getElementById("app")

const root = createRoot(container)
root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <App />
  </Suspense>
)
