import PropTypes from "prop-types"
import React from "react"

const styles = {
  flexParent: {
    display: "flex",

    flexDirection: "column"
  },
  flexChild: {
    flex: "1 1 auto"
  }
}

export class FlexParent extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    flex: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    style: PropTypes.object
  }
  render() {
    let { children, flex, style, ...other } = this.props
    style = Object.assign({}, style)
    if (flex) {
      style.flex = flex
    }
    return (
      <div css={styles.flexParent} style={style} {...other}>
        {children}
      </div>
    )
  }
}

export class FlexChild extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }
  render() {
    return <div css={styles.flexChild}>{this.props.children}</div>
  }
}
