import React, { forwardRef, useState } from "react"
import PropTypes from "prop-types"
import Icon from "./icons/icons"
import { TextArea } from "./input"
/*
 * cell.js
 *
 * Definiert Editierbare "zellen",
 * wie im Dienstplan / Personalkalender / etc.
 * verwendet
 *
 */

const ArrowKeys = ["LEFT", "UP", "RIGHT", "DOWN"]

const styles = {
  cell: {
    height: "20px",
    lineHeight: "20px",
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    textShadow: "1px 1px 2px #ccc",
    cursor: "text",
    textAlign: "center",
    "&:hover": {
      zIndex: "50",
      position: "relative"
      //border: "1px solid #ccf"
    },
    border: "1px solid transparent"
  },
  cellEditorCell: {
    height: "20px",
    lineHeight: "20px",
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    textShadow: "1px 1px 2px #ccc",
    cursor: "text",
    textAlign: "left",
    "&:hover": {
      zIndex: "50",
      position: "relative"
      //border: "1px solid #ccf"
    },
    border: "1px solid transparent"
  },
  cellInvalid: {
    color: "#900",
    position: "relative"
  },
  cellSelected: {
    zIndex: "50",
    position: "relative",
    border: "1px solid #09f"
  },
  animate: {
    transition: "all 300ms"
  },
  hiddenInputContainer: {
    // fontSize: "13px",
    // fontWeight: "bold",
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    border: "none",
    textAlign: "center",
    padding: 0,
    boxSizing: "border-box",
    fontFamily: "'RobotoArion', Arial",
    position: "relative",
    alignItems: "center"
  },
  hiddenInput: {
    // fontSize: "13px",
    // fontWeight: "bold",
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    fontSize: "inherit",
    textAlign: "center",
    padding: 0,
    boxSizing: "border-box",
    fontFamily: "'RobotoArion', Arial",
    position: "relative",
    resize: "none"
  },
  cellEditor: {
    height: "20px",
    fontSize: "inherit",
    //textAlign: "inherit",
    textAlign: "left"
  }
}

export const CellEditor = ({
  value,
  title,
  onContextMenu,
  style,
  className,
  onChange,
  onFocus,
  area,
  autoFocus,
  onDoubleClick,
  disabled,
  ...props
}) => {
  const [active, setActive] = useState(false)
  const handleSelect = () => {
    setActive(true)
  }
  const handleDeselect = () => {
    setActive(false)
  }
  if (!active) {
    return (
      <div
        style={style}
        css={styles.cellEditorCell}
        className={className}
        data-fz={value}
        onClick={handleSelect}
        title={title}
        onContextMenu={onContextMenu}
      >
        {value}
      </div>
    )
  } else {
    return (
      <div
        css={[styles.cellSelected, styles.cellEditorCell]}
        className={className}
        data-fz={value}
        onClick={handleSelect}
        title={title}
        onContextMenu={onContextMenu}
      >
        <HiddenInput
          css={styles.cellEditor}
          value={value}
          onChange={onChange}
          onFocus={(ev) => {
            onFocus && onFocus(ev)
          }}
          area={area}
          onBlur={handleDeselect}
          autoFocus={true}
          onDoubleClick={onDoubleClick}
          disabled={disabled}
        />
      </div>
    )
  }
}

export default class EditableCell extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onTab: PropTypes.func,
    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onBlur: PropTypes.func,
    isValid: PropTypes.bool,
    selected: PropTypes.bool,
    active: PropTypes.bool,
    title: PropTypes.string,
    height: PropTypes.string,
    onContextMenu: PropTypes.func,
    onArrowKey: PropTypes.func,
    border: PropTypes.bool,
    feiertag: PropTypes.string,
    hovering: PropTypes.bool,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    style: PropTypes.object,
    autoFocus: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    area: PropTypes.bool,
    className: PropTypes.string
  }
  static defaultProps = {
    autoFocus: true
  }

  constructor(props) {
    super(props)
    this.select = this.select.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }
  select(e) {
    this.props.onFocus && this.props.onFocus(e)
    this.props.onSelect && this.props.onSelect(e)
  }
  handleBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }
  handleKeyPress(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
    if (e.which === 9 && this.props.onTab) {
      if (e.shiftKey === true) {
        this.props.onTab(true)
      } else {
        this.props.onTab(false)
      }
      e.preventDefault()
    }
    if (e.which <= 40 && e.which >= 37 && this.props.onArrowKey) {
      this.props.onArrowKey(ArrowKeys[e.which - 37], e.shiftKey, e.altKey)
    }
  }
  render() {
    let value = this.props.value || ""
    if (this.props.active === false) {
      return (
        <div
          style={this.props.style}
          css={[styles.cell, this.props.selected ? styles.cellSelected : null]}
          className={this.props.className}
          data-fz={this.props.value}
          onClick={this.select}
          title={this.props.title}
          onContextMenu={this.props.onContextMenu}
        >
          {this.props.value}
        </div>
      )
    } else {
      return (
        <div
          css={[
            [
              styles.cell,
              this.props.selected ? styles.cellSelected : null,
              this.props.animate ? styles.animate : null
            ]
          ]}
          className={this.props.className}
          data-fz={this.props.value}
          onClick={this.select}
          title={this.props.title}
          onContextMenu={this.props.onContextMenu}
        >
          <HiddenInput
            ref={(ref) => {
              this.input = ref ? ref.input : ref
            }}
            value={value}
            onChange={this.props.onChange}
            onFocus={(ev) => {
              if (this.props.selectOnFocus) {
                ev.target.select()
              }
              this.props.onFocus && this.props.onFocus(ev)
            }}
            area={this.props.area}
            onKeyDown={this.handleKeyPress}
            onBlur={this.handleBlur}
            autoFocus={this.props.autoFocus}
            onDoubleClick={this.props.onDoubleClick}
            disabled={this.props.disabled}
          />
        </div>
      )
    }
  }
}

export const HiddenInput = forwardRef(
  (
    { onChange, onEdited, area, value, className, isClearable, ...rest },
    ref
  ) => {
    const handleChange = (ev) => {
      if (onChange) {
        onChange(ev)
      }
      if (onEdited) {
        let value = ev.currentTarget.value
        onEdited(value)
      }
    }

    return area ? (
      <TextArea
        withEvent
        ref={ref}
        onChange={handleChange}
        css={styles.hiddenInput}
        value={value || ""}
        {...rest}
      />
    ) : (
      <div css={styles.hiddenInputContainer}>
        <input
          ref={ref}
          type="text"
          onChange={handleChange}
          css={styles.hiddenInput}
          className={className}
          value={value || ""}
          {...rest}
        />
        {isClearable && value && (
          <Icon
            icon="xmark"
            css={{
              position: "absolute",
              right: "0",
              color: "#999",
              opacity: "0.2",
              "&:hover": {
                opacity: "1"
              }
            }}
            onClick={(ev) => {
              ev.stopPropagation()
              handleChange({
                target: { value: null, setSelectionRange: () => {} },
                currentTarget: { value: null },
                persist: () => {}
              })
            }}
          />
        )}
      </div>
    )
  }
)

HiddenInput.propTypes = {
  styles: PropTypes.object,
  onChange: PropTypes.func,
  onEdited: PropTypes.func,
  area: PropTypes.bool,
  value: PropTypes.string,
  className: PropTypes.string,
  isClearable: PropTypes.bool
}
