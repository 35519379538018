import { useReadonly } from "app/shared/sperrdatum"
import to from "await-to-js"
import { Button as PButton } from "primereact/button"
import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import Icon from "./icons/icons"
import { useToast } from "./toast"
import Tooltip from "./tooltip"
const styles = {
  button: {
    padding: "0.5em 1em",
    borderRadius: "3px",
    backgroundColor: "transparent",

    fontSize: "12px",
    lineHeight: "1",
    position: "relative",
    margin: "1px",
    background: "transparent",
    border: "none !important",
    color: "#7f7f7f",
    cursor: "pointer",
    outline: "0.5px solid transparent",
    transition: "color 750ms",

    "&:hover": {
      backgroundColor: "#dedede",
      color: "#fefefe",
      backgroundImage: "none",
      filter: "none",
      border: "1px solid #ccc"
    },
    "&:active": {
      backgroundImage: "none",
      filter: "none"
    },
    "&:disabled": {
      color: "#dfdfdf"
    },
    "&:focus": {
      borderRadius: "3px",
      borderColor: "#00cccc",
      outline: "0.5px solid #ccc"
    }
  },
  primary: {
    backgroundColor: "#0cf"
  },
  microButton: {
    padding: "0",
    borderRadius: "3px",
    backgroundColor: "#fefefe",

    fontSize: "9px",
    lineHeight: "1",
    position: "relative",
    margin: "1px",
    background: "transparent",
    border: "none !important",
    color: "#7f7f7f",
    cursor: "pointer",
    outline: "none",
    transition: "color 750ms",
    "&:hover": {
      backgroundColor: "#dedede",
      color: "#fefefe",
      backgroundImage: "none",
      filter: "none",
      border: "1px solid #ccc"
    },
    "&:active": {
      backgroundImage: "none",
      filter: "none"
    },
    "&:disabled": {
      color: "#dfdfdf"
    }
  },

  buttonExpanded: {
    width: "100%"
  },
  buttonFlex: {
    alignItems: "center",
    flex: "1",
    padding: "0.5em 0em"
  },
  buttonActive: {
    color: "#00ffff",
    borderColor: "#00ffff",
    "&:focus": {
      color: "#00ffff",
      borderColor: "#00ffff"
    }
  },
  buttonInactive: {
    borderRadius: "3px",
    border: "1px solid #ccc"
  },
  buttonGroup: {
    margin: "0",
    display: "flex"
  },
  wrapperFlex: {
    flex: 1,
    display: "flex"
  }
}

export const MicroButton = React.forwardRef(
  (
    {
      children,
      onClick,
      active,
      isPrimary,
      onFocus,
      onMouseOver,
      onMouseLeave,
      onBlur,
      title,
      expand,
      className,
      flex,
      disabled,
      disableOnReadOnly,
      ...props
    },
    ref
  ) => {
    const rO = useReadonly({ readOnly: disabled })

    let activeStyle = null
    if (active == true) {
      activeStyle = styles.buttonActive
    } else if (active == false) {
      activeStyle = styles.buttonInactive
    }
    return (
      <Tooltip title={title || ""} disableHoverListener={!title}>
        <span className={className} css={flex ? styles.wrapperFlex : null}>
          <button
            ref={ref}
            data-title={title}
            {...props}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={(disableOnReadOnly && rO) || disabled}
            onClick={onClick}
            css={[
              styles.microButton,
              expand && styles.buttonExpanded,
              activeStyle,
              flex && styles.buttonFlex
            ]}
            className={className}
          >
            {children}
          </button>
        </span>
      </Tooltip>
    )
  }
)

export const PrimeButton = PButton

export const Button = forwardRef(
  (
    {
      flex,
      expand,
      isPrimary = false,
      disableOnPermisson,
      disabled,
      active,
      children,
      ...props
    },
    ref
  ) => {
    const readOnly = useReadonly({ readOnly: disabled })

    return (
      <PButton
        ref={ref}
        disabled={disableOnPermisson ? readOnly : disabled}
        css={[
          flex && styles.buttonFlex,
          expand && styles.buttonExpanded,

          !isPrimary && {
            backgroundColor: "transparent",
            color: "#666",
            borderColor: "transparent",
            "&:hover": {
              "&:not(:disabled)": {
                backgroundColor: "#dedede",
                color: "#fefefe",
                borderColor: "#dedede"
              },
              background: "#dedede",
              backgroundColor: "#dedede",
              color: "#666",
              filter: "none",
              border: "1px solid #ccc"
            }
          },
          { justifyContent: "center", height: "1.8em" },
          active && styles.buttonActive
        ]}
        {...props}
      >
        {children}
      </PButton>
    )
  }
)

export default Button

export const ButtonGroup = ({ children, className }) => {
  return (
    <div className={className} css={styles.buttonGroup}>
      {children}
    </div>
  )
}

export const ButtonRadio = ({
  values = [],
  selected = null,
  onChange = () => {}
}) => {
  return (
    <ButtonGroup>
      {values.map((v) => (
        <Button
          key={v.value}
          active={selected == v.value}
          onClick={() => {
            onChange(v.value)
          }}
        >
          {v.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export const SaveButton = ({ saveAction, disabled }) => {
  const { t } = useTranslation()
  const { show } = useToast()

  const save = async () => {
    const [err, resp] = await to(saveAction())
    if (err) {
      show({ summary: "Fehler beim Speichern", severity: "error" })
    } else {
      show({ summary: "Änderungen gespeichert", severity: "success" })
    }
  }

  return (
    <Button
      disabled={disabled || !saveAction}
      tooltip={t("Daten Speichern")}
      onClick={save}
    >
      <Icon icon="floppy-disk" />
    </Button>
  )
}
