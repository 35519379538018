import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "zustand/middleware/immer"
import { devtools } from "zustand/middleware"
import request from "superagent"

const useMenuStore = create(
  immer((set, get) => ({
    error: null,
    items: [],
    groups: [],
    visible: false,
    actions: {
      ToggleMenu: () => {
        set((state) => {
          state.visible = !state.visible
          //state.groups = [...state.groups]
          //state.items = [...state.items]
        })
      },
      SaveFavs: async () => {
        const favs = get()
          .items.filter((i) => i.is_fav)
          .filter((i) => i.enabled && i.visible)
          .sort((a, b) => a.pos - b.pos)
        await request.post("/api/favs").send(favs)
      },

      SetFav: (item) => {
        set((state) => {
          const favs = state.items
            .filter((i) => i.is_fav)
            .sort((a, b) => a.pos - b.pos)
          const sItem = state.items.find((i) => i.name === item.name)
          if (sItem) {
            sItem.is_fav = true
            sItem.pos = favs[favs.length - 1].pos + 1
            sItem.color = "#999"
          }
          state.items = state.items.sort((a, b) => a.pos - b.pos)
        })
        get().actions.SaveFavs()
      },
      SwitchFav: (oldItem, newItem) => {
        set((state) => {
          const favs = state.items
            .filter((i) => i.is_fav)
            .sort((a, b) => a.pos - b.pos)
          const sItem = state.items.find((i) => i.name === oldItem.name)
          if (sItem) {
            const nItem = state.items.find((i) => i.name === newItem.name)
            if (nItem) {
              nItem.is_fav = true
              nItem.pos = sItem.pos
              nItem.color = sItem.color
            }
            sItem.is_fav = false
            sItem.pos = 0
          }

          state.items = state.items.sort((a, b) => a.pos - b.pos)
        })
        get().actions.SaveFavs()
      },
      SetItemColor: (item, color) => {
        set((state) => {
          const sItem = state.items.find((i) => i.name === item.name)
          if (sItem) {
            sItem.color = color
          }
        })
        get().actions.SaveFavs()
      },
      UnsetFav: (item) => {
        set((state) => {
          const sItem = state.items.find((i) => i.name === item.name)
          if (sItem) {
            sItem.is_fav = false
            sItem.pos = 0
          }
          state.items = state.items.sort((a, b) => a.pos - b.pos)
        })
        get().actions.SaveFavs()
      },
      ReorderFavMenu: (dragIndex, hoverIndex) => {
        set((state) => {
          const items = [...state.items]
            .filter((i) => {
              return i.is_fav && i.enabled && i.visible
            })
            .sort((a, b) => a.pos - b.pos)
          const dragItem = items[dragIndex]
          items.splice(dragIndex, 1)
          items.splice(hoverIndex, 0, dragItem)
          let index = 0
          state.items = state.items
            .map((i) => {
              if (i.is_fav) {
                i.pos = items.indexOf(i)
                //return newItems.shift()
              }
              console.log(i.group, i.name, i.pos)
              return i
            })
            .sort((a, b) => {
              if (a.pos < b.pos) {
                return -1
              }
              if (a.pos > b.pos) {
                return 1
              }
              return 0
            })
        })
        get().actions.SaveFavs()
      },
      Reset: () => {
        set((state) => {
          state.visible = false
          state.groups = []
          state.items = []
        })
      },
      LoadMenu: async () => {
        if (get().groups.length > 0) {
          return
        }
        if (get().items.length > 0) {
          return
        }
        return new Promise((resolve, reject) => {
          request("/api/groups").end((error, response) => {
            if (error) {
              reject(error)
            } else {
              set((state) => {
                state.groups = response.body
              })
            }
          })
          request("/api/menu").end((error, response) => {
            if (error) {
              reject(error)
            } else {
              set((state) => {
                state.items = response.body
              })
            }
          })
        })
      }
    }
  }))
)

export default useMenuStore
