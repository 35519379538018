import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "zustand/middleware/immer"
import { devtools } from "zustand/middleware"
import request from "superagent"
import to from "await-to-js"
import { useEffect } from "react"

const useAtabStore = create(
  immer(
    devtools((set, get) => ({
      atabs: {},
      actions: {
        loadAtab: async (tab_art, tab_key) => {
          const [err, resp] = await to(
            request.get(`/api/parameter/${tab_art}/${tab_key}`)
          )
          if (err) {
            console.error(err)
            return
          }
          set((state) => {
            state.atabs[`${tab_art}/${tab_key}`] = resp.body
          })
        }
      }
    }))
  )
)

export const useAtab = (tab_art, tab_key) => {
  const store = useAtabStore()
  useEffect(() => {
    store.actions.loadAtab(tab_art, tab_key)
  }, [tab_art, tab_key])
  const atab = store.atabs[`${tab_art}/${tab_key}`]
  if (atab) {
    return atab.tab_wert
  }
  return undefined
}

export default useAtabStore
