import PropTypes from "prop-types"
import React from "react"

const styles = {
  floatRight: {
    float: "right",
    "&:after": {
      clear: "both"
    }
  },
  floatLeft: {
    float: "left",
    "&:after": {
      clear: "both"
    }
  }
}

export const FloatRight = ({ children }) => {
  return <div css={styles.floatRight}>{children}</div>
}

export const FloatLeft = ({ children }) => {
  return <div css={styles.floatLeft}>{children}</div>
}
