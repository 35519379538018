import { locale } from "primereact/api"

import i18n from "app/i18n"
import useDropDownStore from "app/shared/dropdown/state"
import { Box, Select } from "app/shared/ui"
import { NumberField, VLabel } from "app/shared/ui/input"
import { Settings as LuxonSettings } from "luxon"
import { useTranslation } from "react-i18next"
import { createWithEqualityFn as create } from "zustand/traditional"
import { persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { useMemo } from "react"
import { useVersion } from "app/shared/version"

const Settings = () => {
  const { fontSize, setFontSize } = useSettingsStore()
  const { setLanguage, language } = useSettingsStore()
  const { menuStyle, setMenuStyle } = useSettingsStore()

  const { i18n, t } = useTranslation()
  return (
    <>
      <div>
        <VLabel label={t("Relative Größe")}>
          <NumberField
            value={fontSize}
            onChange={(ev, value) => setFontSize(value)}
          />
        </VLabel>
        <VLabel label={t("Sprache")}>
          <Select
            value={language}
            options={[
              { label: "Deutsch", value: "de" },
              { label: "English", value: "en" }
            ]}
            onChange={(value) => {
              setLanguage(value)
            }}
          />
        </VLabel>

        <VLabel label={t("Menü")}>
          <Select
            value={menuStyle}
            options={[
              { label: "Panel", value: "panel" },
              { label: "Vollbild", value: "fullscreen" }
            ]}
            onChange={(value) => {
              setMenuStyle(value)
            }}
          />
        </VLabel>
      </div>
      <VersionInfo />
    </>
  )
}

export const VersionInfo = () => {
  const versionInfo = useVersion()
  if (versionInfo === null) {
    return null
  }
  return (
    <Box label="Versions Informationen">
      <div css={{ padding: "3px", textAlign: "center" }}>
        <div css={{ fontWeight: "bold" }}>{versionInfo.version}</div>
        <div>{versionInfo.build}</div>
        <div>{versionInfo.hash}</div>
        {versionInfo.build_type === "dev" && <div>Development Build</div>}
      </div>
    </Box>
  )
}

export const useSettingsStore = create(
  immer(
    persist(
      (set, get) => ({
        fontSize: 11,
        language: "de",
        menuStyle: "panel",
        setFontSize: (size) => {
          set((state) => {
            state.fontSize = size
          })
        },
        setLanguage: (lang) => {
          console.log(lang)
          LuxonSettings.defaultLocale = lang
          i18n.changeLanguage(lang)
          locale(lang)
          useDropDownStore.getState().clearDropDowns()
          set((state) => {
            state.language = lang
          })
        },
        setMenuStyle: (style) => {
          set((state) => {
            state.menuStyle = style
          })
        }
      }),
      {
        name: "settings",
        onRehydrateStorage: (state) => {
          return (state, error) => {
            LuxonSettings.defaultLocale = state.language
            locale(state.language)
            i18n.changeLanguage(state.language)
          }
        }
      }
    )
  )
)

export const usePxToRem = (fontSize) => {
  const { fontSize } = useSettingsStore()
  const activeRowSize = useMemo(() => {
    return parseInt(fontSize) / 11
  }, [fontSize])
}

useSettingsStore.subscribe(
  (state) => {
    LuxonSettings.defaultLocale = state.language
    locale(state.language)
    i18n.changeLanguage(state.language)
  },
  (state) => state.language
)

export default Settings
