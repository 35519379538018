import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "zustand/middleware/immer"
import { devtools } from "zustand/middleware"
import request from "superagent"
import Datetime from "app/shared/dateutil"
import to from "await-to-js"
import { arrayToObject } from "app/shared/util/conversion"

const useDropDownStore = create(
  immer(
    devtools(
      (set, get) => ({
        dropdowns: {},
        errors: {},
        clearDropDowns: () => {
          set((state) => {
            state.dropdowns = {}
            state.errors = {}
          })
        },
        fetchDropDown: async (
          key,
          {
            extra,
            year,
            month,
            day,
            useFilter,
            qkey,
            ressourceGroup,
            ressourceName,
            force,
            lang
          }
        ) => {
          let datum = new Date()
          if (year) {
            datum.setFullYear(year)
          }
          if (month) {
            datum.setMonth(parseInt(month) - 1)
          }
          if (day) {
            datum.setDate(day)
          } else {
            datum.setDate(1)
          }
          if (!key) {
            return
          }
          let dataKey =
            key +
            "/" +
            (extra || "") +
            (year || "") +
            (month || "") +
            (day || "") +
            (useFilter ? "[filter]" : "")
          if (qkey) {
            dataKey = qkey
          }
          let data = get().dropdowns[dataKey]
          if (data && !force) {
            return
          }

          // TODO: UserID!
          //let userID = getState().currentUser.user.user_id
          const userID = 6
          set((state) => {
            state.dropdowns[dataKey] = {
              data: [],
              byKey: {},
              isFetching: true,
              error: false
            }
          })
          const [err, resp] = await to(
            request.get("/api/dropdown").query({
              name: key,
              value: extra,
              useFilter: useFilter ? "1" : "0",
              userID: useFilter ? userID : 0,
              ressourceGroup: ressourceGroup,
              ressourceName: ressourceName,
              lang: lang || "de",
              datum: Datetime.fromJSDate(datum).toISO() // dateFns.format(datum, "YYYY-MM-DD") + "T00:00:00Z"
            })
          )

          if (err) {
            set((state) => {
              state.errors[dataKey] = err
              state.dropdowns[dataKey] = {
                data: [],
                byKey: {},
                isFetching: false,
                error: true
              }
            })
            return
          }
          set((state) => {
            const data = resp.body
            state.errors[dataKey] = undefined
            state.dropdowns[dataKey] = {
              data: data || [],
              byKey: arrayToObject(data, "value"),
              isFetching: false,
              error: false
            }
          })
        },
        fetchOption: async (key, value) => {
          const dataKey = key + "/"
          const [err, resp] = await to(
            request.get("/api/find/options").query({ name: key, value: value })
          )
          if (err) {
            set((state) => {
              state.errors[dataKey] = err
              state.dropdowns[dataKey] = {
                data: [],
                byKey: {},
                isFetching: false,
                error: true
              }
            })
            return
          }
          set((state) => {
            const data = resp.body
            state.errors[dataKey] = undefined
            state.dropdowns[dataKey] = {
              data: data || [],
              byKey: arrayToObject(data, "value"),
              isFetching: false,
              error: false
            }
          })
        }
      }),
      { name: "Zustand", store: "DropDown" }
    )
  )
)

export default useDropDownStore
