import { TabView, TabPanel as PTabPanel } from "primereact/tabview"
import { TabMenu } from "primereact/tabmenu"
import { Children, cloneElement, useEffect, useMemo, useState } from "react"
import { Grid, Row } from "app/shared/ui/grid"
import { logDeprecation } from "../util/deprecate"
import { useSearchParams } from "react-router-dom"
import { Fade } from "./transition"

export const SimpleTabs = ({ children, ...rest }) => {
  return (
    <TabView
      {...rest}
      /*activeIndex={value} onTabChange={onChange}*/
    >
      {children}
    </TabView>
  )
}

export const Tabs = ({ children, value, onChange, style }) => {
  const result = Children.toArray(children)
  const [activeIndex, setActiveIndex] = useState(0)
  const [header, ...tabs] = result
  useEffect(() => {
    logDeprecation("Tabs component is deprecated. Use SimpleTabs instead.")
  }, [])
  const tabHeader = cloneElement(header, {
    ...header.props,
    onChange: (v, i) => {
      setActiveIndex(i)
      onChange && onChange(v)
    },
    value: value,
    activeIndex: activeIndex
  })
  return (
    <Grid css={{ width: "100%" }}>
      <Row fixed height={"20px"}>
        {tabHeader}
      </Row>
      <Row>
        <div css={{ position: "relative", width: "100%" }}>
          {(tabs[activeIndex] && tabs[activeIndex].props.children) || null}
        </div>
      </Row>
    </Grid>
  )
}

export const TabPanel = PTabPanel

//   = ({ header, children }) => {
//   return <PTabPanel header={header}>{children}</PTabPanel>
// }

export const Tab = () => {}

export const TabList = ({ children, value, onChange, activeIndex }) => {
  //const [activeIndex, setActiveIndex] = useState(0)
  const items = useMemo(() => {
    return Children.map(children, (c) => {
      return {
        label: c.props.label,
        value: c.props.value,
        template: TabHeaderTemplate,
        icon: c.props.icon,
        style: c.props.style
      }
    })
  }, [children])
  const onTabChange = (e) => {
    //setActiveIndex(e.index)
    const v = items[e.index].value
    onChange(v, e.index)
  }

  useEffect(() => {
    const index = items.findIndex((i) => i.value === value)
    if (index !== -1) {
      //setActiveIndex(index)
    }
  }, [value, items])

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    />
  )
}

const TabHeaderTemplate = (item, options) => {
  return (
    <button
      type="button"
      onClick={options.onClick}
      className={options.className}
      css={item.style}
    >
      <div
        css={{
          fontSize: "9px",
          fontWeight: 100,
          display: "flex",
          flexDirection: "row",
          gap: "1px"
        }}
      >
        {item.icon ? (
          <span css={{ marginRight: "2px" }}>{item.icon}</span>
        ) : null}
        {item.label}
      </div>
    </button>
  )
}

/*
Synchronisiert den Tabindex mit der URL Query Parameter "tab"
*/
export const useSyncedTabIndex = (defaultIndex) => {
  const [tabIndex, setTabIndex] = useState(defaultIndex)
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.has("tab")) {
      setTabIndex(parseInt(searchParams.get("tab")))
    }
  }, [])

  useEffect(() => {
    if (tabIndex === 0 && !searchParams.has("tab")) {
      return
    }
    setSearchParams({ tab: tabIndex })
  }, [tabIndex])
  return [tabIndex, setTabIndex]
}

export const SmartTabs = Tabs

export const TabPanelFlex = TabPanel
