import React from "react"
import { Calendar } from "primereact/calendar"
import { useReadonly } from "app/shared/sperrdatum"
import { useSettingsStore } from "app/settings/appsettings/index"

const hiddenStyle = {
  border: "none",
  borderRadius: 0,
  backgroundColor: "transparent"
}

const DatePicker = ({
  selected,
  onChange,
  hidden,
  className = "w-full",
  label,
  flex,
  disabled,
  noConvert = false,
  minDate,
  maxDate,
  placeholder,
  ...props
}) => {
  const rO = useReadonly({ readOnly: disabled })
  const languege = useSettingsStore((state) => state.language)
  if (label) {
    return (
      <div className="flex flex-column gap-0" css={flex ? { flex: 1 } : null}>
        <label
          css={{ fontSize: "10px", color: "#666" }}
          className="flex flex-column gap-0"
        >
          {label}
          <Calendar
            placeholder={placeholder}
            className={className}
            value={convertFromUTC(selected)}
            minDate={minDate ? convertFromUTC(minDate) : null}
            maxDate={maxDate ? convertFromUTC(maxDate) : null}
            onChange={(props) => {
              if (props.value == "00.00.0000") {
                onChange(null)
              }
              if (props.value && !props.value.getDate) {
                return
              }
              onChange(convertToUTC(props.value))
            }}
            mask={getMask(languege, props.view, props.showTime, props.timeOnly)}
            maskSlotChar={props.maskSlotChar ? props.maskSlotChar : " "}
            {...props}
            inputStyle={hidden ? hiddenStyle : undefined}
            disabled={rO}
          />
        </label>
      </div>
    )
  }
  return (
    <Calendar
      placeholder={placeholder}
      className={className}
      value={convertFromUTC(selected)}
      minDate={minDate ? convertFromUTC(minDate) : null}
      maxDate={maxDate ? convertFromUTC(maxDate) : null}
      onChange={(props) => {
        if (props.value == "00.00.0000") {
          onChange(null)
        }
        if (props.value && !props.value.getDate) {
          return
        }
        onChange(convertToUTC(props.value))
      }}
      mask={getMask(languege, props.view, props.showTime, props.timeOnly)}
      maskSlotChar={props.maskSlotChar ? props.maskSlotChar : " "}
      {...props}
      inputStyle={hidden ? hiddenStyle : undefined}
      disabled={rO}
    />
  )
}

export const getMask = (language, viewMode, showTime, timeOnly) => {
  if (viewMode === "year") {
    return "9999"
  }
  if (viewMode === "month") {
    return "99/9999"
  }

  if (language === "de") {
    if (showTime) {
      if (timeOnly) {
        return "99:99"
      }
      return "99.99.9999 99:99"
    }
    return "99.99.9999"
  }
  if (showTime) {
    if (timeOnly) {
      return "99:99"
    }
    return "99/99/9999 99:99"
  }
  return "99/99/9999"
}

const convertFromUTC = (m) => {
  if (!m) {
    return null
  }
  return new Date(
    m.getUTCFullYear(),
    m.getUTCMonth(),
    m.getUTCDate(),
    m.getUTCHours(),
    m.getUTCMinutes()
  )
}

const convertToUTC = (m) => {
  if (!m) {
    return null
  }
  if (!m.getDate) {
    return null
  }
  return new Date(
    Date.UTC(
      m.getFullYear(),
      m.getMonth(),
      m.getDate(),
      m.getHours(),
      m.getMinutes()
    )
  )
}

export default DatePicker
