import { useReadonly } from "app/shared/sperrdatum"
import { Dropdown as PDropDown } from "primereact/dropdown"
import { MultiSelect as PMultiSelect } from "primereact/multiselect"
import React, { forwardRef, useMemo } from "react"

const emptyArr = []

export const Select = forwardRef(
  (
    {
      value = "",
      onChange,
      options = [],
      defaultValue,
      multi,
      label,
      placeholder,
      valueKey,
      labelKey,
      onInputChange,
      disableClearable,
      showClear = true,
      panelStyle,
      filter = true,
      editable = false,
      flex,
      className,
      disabled,
      filterFunc,
      autoFocus,
      showID,
      defaultFilter,
      onDoubleClick,
      ...rest
    },
    ref
  ) => {
    const rO = useReadonly({ readOnly: disabled })

    let Parent = EmptyParent
    if (label) {
      Parent = SelectWithLabel
    }

    const filteredOptions = useMemo(() => {
      if (defaultFilter) {
        return options.filter((v) => {
          // if the value is the same as the current value, return true
          if (v.value === value) {
            return true
          }
          return defaultFilter(v)
        })
      }
      return options
    }, [options, defaultFilter])

    const handleKeyDown = (e) => {
      if (
        !!onChange &&
        !!options &&
        !!(valueKey || "value") &&
        !!(labelKey || "label") &&
        // check if key is 'Enter' or 'Space'
        (e.originalEvent.code === "Enter" || e.originalEvent.code === " ")
      ) {
        // find the selected option manually based on the text content of the event target
        const selectedOption = options.find(
          (option) =>
            option[labelKey || "label"] === e.originalEvent.target.textContent
        )

        // if a matching option is found, add the value to the event object to match behavior of onClick event
        if (selectedOption) {
          e.value = selectedOption[valueKey || "value"]
          onChange(e.value)
        }
      }
    }

    const handleChange = (e) => {
      if (onChange) {
        if (!!(valueKey || "value") && e.originalEvent?.type === "keydown") {
          handleKeyDown(e)
        } else {
          onChange(e.value, e)
        }
      }
    }
    if (filterFunc) {
      options = options.filter(filterFunc)
    }

    if (multi) {
      const { valueTemplate, ...other } = rest
      return (
        <Parent label={label} flex={flex}>
          <PMultiSelect
            value={value || emptyArr}
            options={filteredOptions}
            maxSelectedLabels={1}
            selectedItemsLabel="{0} ausgewählt"
            optionLabel={labelKey || "label"}
            field={labelKey || "label"}
            css={{ width: "100%" }}
            className={className}
            virtualScrollerOptions={{ itemSize: 18 }}
            filterBy={"label,value"}
            onChange={(ev) => onChange(ev.value)}
            multiple={multi}
            showClear
            display="comma"
            panelStyle={panelStyle}
            filter
            disabled={rO}
            ref={ref}
            {...other}
          />
        </Parent>
      )
    } else {
      return (
        <Parent label={label} flex={flex}>
          <PDropDown
            pt={{
              root: {
                onDoubleClick: onDoubleClick
              }
            }}
            value={value}
            showOnFocus={true}
            options={filteredOptions}
            editable={editable}
            optionValue={valueKey || "value"}
            optionLabel={labelKey || "label"}
            css={{ width: "100%", height: "1.8em" }}
            className={className}
            filterBy={`${valueKey || "value"},${labelKey || "label"}`}
            panelStyle={panelStyle}
            onChange={handleChange}
            showClear={showClear}
            filter={filter}
            disabled={rO}
            ref={ref}
            {...rest}
          />
        </Parent>
      )
    }
  }
)

export const EmptyParent = ({ children }) => {
  return <>{children}</>
}

export const SelectWithLabel = React.forwardRef(
  ({ label, help, flex, children, ...rest }, ref) => {
    return (
      <div className="flex flex-column gap-0" css={flex ? { flex: 1 } : null}>
        <label
          css={{ fontSize: "10px", color: "#666" }}
          className="flex flex-column gap-0"
        >
          {label}
          {children}
        </label>
        {help && <small css={{ fontSize: "9px", color: "#666" }}>{help}</small>}
      </div>
    )
  }
)

export default Select
