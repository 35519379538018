import { Panel, TextField } from "app/shared/ui"
import { Button } from "app/shared/ui/button"
import React, { useEffect } from "react"
import swal from "sweetalert"

import { useAtab } from "app/shared/atab/state"
import { SpinnerIcon } from "app/shared/ui/icons/icons"
import request from "superagent"

const loginInputStyle = {
  height: "25px",
  width: "100%",
  marginBottom: "8px",
  marginTop: "14px"
}
export const RequestResetDialog = ({ onClose }) => {
  const [user, setUser] = React.useState("")
  const [page, setPage] = React.useState(0)
  return (
    <Panel style={{ height: "100%" }}>
      <Panel.header>
        Kennwort Zurücksetzen
        <span onClick={onClose} style={{ position: "absolute", right: "12px" }}>
          X
        </span>
      </Panel.header>
      <Panel.body style={{ height: "calc(100% - 80px)" }}>
        {page === 0 && (
          <Page1 user={user} setUser={setUser} goToNext={() => setPage(1)} />
        )}
        {page === 1 && (
          <Page2
            user={user}
            setUser={setUser}
            goBack={() => setPage(0)}
            onClose={onClose}
          />
        )}
      </Panel.body>
    </Panel>
  )
}

const Page1 = ({ user, setUser, goToNext }) => {
  return (
    <>
      <TextField
        label="User"
        value={user}
        onChange={(ev) => setUser(ev.currentTarget.value)}
        autoFocus
      />
      <Button
        block
        styleName="login-button"
        className="default-gradient"
        type="submit"
        style={{ marginTop: "12px" }}
        disabled={!user}
        onClick={goToNext}
      >
        Weiter
      </Button>
    </>
  )
}
/*
* 	UserName    string `json:"user_name"`
	Code        string `json:"code"`
	PersID      string `json:"pers_id"`
	NewPassword string `json:"new_pass"`
* */

const Page2 = ({ user, goBack, onClose }) => {
  const [persID, setPersID] = React.useState("")
  const [code, setCode] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [newPasswordAgain, setNewPasswordAgain] = React.useState("")
  const [sendAgain, setSendAgain] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const noPersID = useAtab("arion", "reset-no-pers-id", true)
  useEffect(() => {
    setLoading(true)
    request.get(`/api/auth/reset/${user}`).then((res) => {
      setLoading(false)
      if (res.status === 200) {
        swal("Email versendet", "Bitte prüfen Sie Ihr Postfach", "success")
      } else {
        swal("Fehler", "Bitte prüfen Sie Ihre Eingabe", "error")
      }
    })
  }, [sendAgain])
  const requestReset = () => {
    if (newPassword !== newPasswordAgain) {
      swal("Fehler", "Die Kennwörter stimmen nicht überein", "error")
      return
    }
    setLoading(true)
    request
      .post("/api/auth/doreset/")
      .send({
        user_name: user,
        new_pass: newPassword,
        code: code,
        pers_id: persID
      })
      .end((error, response) => {
        setLoading(false)
        if (response.status === 200) {
          swal(
            "Passwort erfolgreich zurückgesetzt",
            "Sie können sich nun mit dem neuen Kennwort anmelden.",
            "success"
          )
          onClose()
        } else {
          swal("Fehler", "Bitte prüfen Sie Ihre Eingabe", "error")
        }
      })
  }
  const canSubmit = () => {
    if (noPersID === "1") {
      return code && newPassword
    }
    return code && persID && newPassword
  }
  if (loading) {
    return (
      <div>
        Bitte warten... <SpinnerIcon />
      </div>
    )
  }
  return (
    <>
      <div>
        Es wurde eine email mit einem Code versendet, dieser ist 10 Minuten
        Gültig. Bitte melden Sie sich mit dem Code und Ihrer Personalnummer an,
        und vergeben Sie ein neues Kennwort.{" "}
        <span
          style={{
            float: "right",
            color: "#09f",
            textDecoration: "underline",
            cursor: "pointer"
          }}
          onClick={() => {
            setSendAgain(sendAgain + 1)
          }}
        >
          Erneut Senden
        </span>
      </div>
      <input
        autoComplete="do-not-autofill"
        name="hidden"
        type="text"
        style={{ display: "none" }}
      />
      <TextField
        label="Code"
        value={code}
        onChange={(ev) => setCode(ev.currentTarget.value)}
        autoFocus
      />
      {noPersID !== "1" && (
        <TextField
          label="Personalnummer"
          value={persID}
          onChange={(ev) => setPersID(ev.currentTarget.value)}
        />
      )}
      <TextField
        label="Neues Kennwort"
        type={"password"}
        value={newPassword}
        autoComplete="new-password"
        onChange={(ev) => setNewPassword(ev.currentTarget.value)}
      />

      <div>
        <Button
          className="default-gradient"
          type="submit"
          style={{ marginTop: "12px" }}
          disabled={!canSubmit()}
          onClick={requestReset}
        >
          Weiter
        </Button>
        <Button
          className="default-gradient"
          type="submit"
          style={{ marginTop: "12px" }}
          disabled={false}
          onClick={goBack}
        >
          Zurück
        </Button>
      </div>
    </>
  )
}
