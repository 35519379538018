import Datetime from "app/shared/dateutil"
import { newStore } from "app/state"
import to from "await-to-js"
import request from "superagent"

const useSperrDatumStore = newStore(
  (set, get) => ({
    sperre: {},
    nosperre: {},
    actions: {
      Reset: () => {
        set((state) => {
          state.sperre = {}
          state.nosperre = {}
        })
      },
      LoadSperre: async (int_id, datum) => {
        if (!int_id) {
          return
        }
        if (get().sperre[int_id]) {
          return
        }
        set((state) => {
          state.sperre[int_id] = "1900-01-01T00:00:00Z"
        })
        const [err, resp] = await to(
          request.get(
            `/api/security/sperrdatum/${int_id}/${Datetime.fromISO(
              datum
            ).toISODate()}`
          )
        )
        if (!err) {
          set((state) => {
            state.sperre[int_id] = resp.body.datum
          })
        }
      },
      LoadNoSperre: async (int_id, user_id) => {
        if (!int_id) {
          return
        }
        if (!user_id) {
          return
        }
        if (get().nosperre[int_id] !== undefined) {
          return
        }
        set((state) => {
          state.nosperre[int_id] = false
        })
        const [err, resp] = await to(
          request.get(`/api/security/nosperre/${int_id}/${user_id}`)
        )
        if (!err) {
          const nosperre = resp.body.int_id !== 0
          set((state) => {
            state.nosperre[int_id] = nosperre
          })
        }
      }
    }
  }),
  "Global",
  "sperrdatum"
)

export default useSperrDatumStore
