import PropTypes from "prop-types"
import React from "react"

const styles = {
  grid: { display: "flex", flexDirection: "column", height: "100%" },
  row: { display: "flex", flex: 1, flexDirection: "row" },
  col: { display: "flex", flex: 1, flexDirection: "column" },
  buttonRow: {
    flex: "0",
    flexBasis: "30px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #eee",
    alignItems: "center"
  },
  gap: (gap) => {
    return { gap: `${gap || 1}px` }
  },
  colRatio: (ratio) => {
    return { flexGrow: ratio || 1 }
  },
  colFixed: { flexGrow: 0, flexShrink: 0, overflow: "hidden" }
}

export const Grid = ({ children, ...props }) => {
  return (
    <div css={styles.grid} {...props}>
      {children}
    </div>
  )
}

export const Row = ({ children, gap = 5, fixed, height, ...props }) => {
  return (
    <div
      css={[
        styles.row,
        styles.gap(gap),
        fixed && styles.colFixed,
        height && { flexBasis: height }
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

export const ButtonRow = ({ children, ...props }) => {
  return <Row css={styles.buttonRow}>{children}</Row>
}

export const Col = ({
  children,
  gap = 5,
  ratio,
  fixed,
  width,
  ref,
  ...props
}) => {
  return (
    <div
      ref={ref}
      css={[
        styles.col,
        styles.gap(gap),
        styles.colRatio(ratio),
        fixed && styles.colFixed,
        width && { flexBasis: width }
      ]}
      {...props}
    >
      {children}
    </div>
  )
}
