import Datetime from "./luxon/index"
export default Datetime

export const Parse = (iso) => {
  return Datetime.fromISO(iso).toJSDate()
}

export const ISO = (jsDate) => {
  return Datetime.fromJSDate(jsDate).toISO()
}

export const ShiftISODate = (isoDate, days) => {
  return new Date(Date.parse(isoDate).valueOf() + 86400000 * days)
    .toISOString()
    .slice(0, 10)
}

export const dateutil = {
  toISO: (d) => {
    return Datetime.fromJSDate(d).toISO()
  },
  toISODate: (d) => {
    return Datetime.fromJSDate(d).toISODate()
  },
  fromISO: (iso) => {
    return Datetime.fromISO(iso).toJSDate()
  },
  formatDateShort: (d) => {
    return Datetime.fromJSDate(d).formatDateShort()
  },
  formatDateTimeShort: (d) => {
    return Datetime.fromJSDate(d).formatDateTimeShort()
  },
  formatTimeShort: (d) => {
    return Datetime.fromJSDate(d).formatTimeShort()
  },
  formatMonthLong: (d) => {
    return Datetime.fromJSDate(d).formatMonthLong()
  },
  formatDayLong: (d) => {
    return Datetime.fromJSDate(d).formatDayLong()
  },
  startOfMonth: (d) => {
    return Datetime.fromJSDate(d).startOf("month").toJSDate()
  },
  startOfDay: (d) => {
    return Datetime.fromJSDate(d).startOf("day").toJSDate()
  },
  endOfMonth: (d) => {
    return Datetime.fromJSDate(d).endOf("month").toJSDate()
  },
  endOfDay: (d) => {
    return Datetime.fromJSDate(d).endOf("day").toJSDate()
  },
  addMonths: (d, nbr) => {
    return Datetime.fromJSDate(d).plus({ months: nbr }).toJSDate()
  },
  addDays: (d, nbr) => {
    return Datetime.fromJSDate(d).plus({ days: nbr }).toJSDate()
  }
}

export const getMonthName = (month) => {
  return Datetime.fromArray([2020, month - 1, 1]).formatMonthLong()
}

const dtCache = {}

export const clearCachedDate = (iso) => {
  delete dtCache[iso]
}

export const clearAllCachedDates = () => {
  for (const key in dtCache) {
    delete dtCache[key]
  }
}

export const useCachedDate = (iso) => {
  if (!dtCache[iso]) {
    dtCache[iso] = Datetime.fromISO(iso)
  }
  return dtCache[iso]
}

const isoCache = {}

export const useCachedISO = (year, month, day) => {
  const iso = `${year}-${month}-${day}`
  if (!isoCache[iso]) {
    isoCache[iso] = Datetime.fromValues(year, parseInt(month) - 1, day).toISO()
  }
  return isoCache[iso]
}
