const defaultHelpPath = "/help/index.md"
var helpPath = null
var helpWindow = null

export default function setHelpPath(path) {
  helpPath = path
  console.log(path)
}

function unsetWindow() {
  helpWindow.removeEventListener("beforeunload", unsetWindow, false)
  helpWindow = null
}

function openPath(path, w, h) {
  if (helpWindow && helpWindow.window && !helpWindow.closed) {
    if (helpWindow.location.href.search(path) === -1) {
      helpWindow.removeEventListener("beforeunload", unsetWindow, false)
      helpWindow.location = path
      helpWindow.addEventListener("beforeunload", unsetWindow, false)
    }
    helpWindow.focus && helpWindow.focus()
    return
  }

  var dualScreenLeft =
    window.screenLeft != undefined ? window.screenLeft : screen.left
  var dualScreenTop =
    window.screenTop != undefined ? window.screenTop : screen.top

  var width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  var height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  var left = width / 2 - w / 2 + dualScreenLeft
  var top = height / 2 - h / 2 + dualScreenTop
  var newWindow = window.open(
    path,
    "Arion Hilfe",
    "scrollbars=yes, toolbar=yes,menubar=no,location=no,directories=no,status=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  )

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
  helpWindow = newWindow
  newWindow.addEventListener("beforeunload", unsetWindow, false)
}

export function triggerHelp() {
  if (helpPath) {
    openPath(helpPath, 700, 900)
    console.log(helpPath)
  } else {
    openPath(defaultHelpPath, 700, 900)
  }
}
