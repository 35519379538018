import React, { lazy, Suspense, useRef } from "react"
import ArionLogon from "app/shared/authentication/logon.js"
import { useAuthStatus } from "app/shared/authentication/hooks"
import { Overlay } from "app/shared/ui/overlay"
import { useEffect } from "react"
import { Toast } from "primereact/toast"
import { ToastContext } from "app/shared/ui/toast"
import { PrimeReactProvider } from "primereact/api"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import i18n from "app/i18n"

// Main Router
const ArionRouterLazy = lazy(() => import("./router/router.js"))

const ArionRouter = () => {
  return (
    <Suspense fallback={<div />}>
      <ArionRouterLazy />
    </Suspense>
  )
}

export const ArionApp = () => {
  const auth_status = useLoginEffect()
  if (auth_status === "UNKNOWN") {
    return <Overlay visible={true} />
  } else if (auth_status === false) {
    return <ArionLogon />
  }
  return <ArionRouter />
}

export const App = () => {
  const ref = useRef(null)
  const value = { locale: i18n.language }

  return (
    <DndProvider backend={HTML5Backend}>
      <PrimeReactProvider value={value}>
        <Toast ref={ref} />
        <ToastContext.Provider
          value={{ showToast: (props) => ref.current.show(props) }}
        >
          <ArionApp />
        </ToastContext.Provider>
      </PrimeReactProvider>
    </DndProvider>
  )
}

const useLoginEffect = () => {
  const auth_status = useAuthStatus()
  useEffect(() => {
    if (auth_status === true) {
      document.body.classList.remove("loggedOut")
      document.body.classList.add("loggedIn")
    } else {
      document.body.classList.add("loggedOut")
      document.body.classList.remove("loggedIn")
    }
  }, [auth_status])
  return auth_status
}
