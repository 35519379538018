import {
  useAllowEmailReset,
  useAuthActions,
  useAuthError,
  useAuthUser
} from "app/shared/authentication/hooks"
import { RequestResetDialog } from "app/shared/authentication/requestReset"
import { ArionImg, TextField } from "app/shared/ui"
import Icon, { SpinnerIcon } from "app/shared/ui/icons/icons"
import Modal from "app/shared/ui/modal"
import { Fade } from "app/shared/ui/transition"
import { useEffect, useMemo, useRef, useState } from "react"
import Button from "../ui/button"
import { Password } from "../ui/input"
const styles = {
  LogonWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#eee"
  },
  Logon: {
    // width: "200px",
    backgroundColor: "#f9f9f9",
    borderRadius: "15px",
    padding: "15px",
    paddingLeft: "0px",
    boxShadow: "5px 5px 5px #ccc",
    border: "1px solid #ccc",
    display: "flex",
    flexDirection: "row"
  },
  LogonControls: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "7px",
    gap: "0.45rem"
  },
  ImageContainer: {
    display: "flex",
    width: "125px",

    alignItems: "center",
    justifyContent: "center"
  },
  ErrorContainer: { fontSize: "9px", color: "#900" },
  LogonButton: { marginTop: "10px" }
}

export const LogonWindow = () => {
  const currentUser = useAuthUser()
  const serverError = useAuthError()
  const actions = useAuthActions()
  const [user, setUser] = useState("")
  const [origPassword, setOrigPassword] = useState("")
  const [password, setPassword] = useState("")
  const [verifyPassword, setVerifiyPassword] = useState("")
  const [clientError, setClientError] = useState(null)
  const [loggingIn, setLoggingIn] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const error = useMemo(() => {
    return serverError || clientError
  }, [serverError, clientError])
  const allow_reset = useAllowEmailReset()
  useEffect(() => {
    setLoggingIn(false)
  }, [error])
  const doLogon = () => {
    setLoggingIn(true)
    actions.Login({ user_name: user, password: password })
  }

  const changePassword = () => {
    if (verifyPassword !== password) {
      setClientError("Passwörter stimmen nicht überein!")
      return
    }
    console.log(origPassword)
    actions.ChangeUserPassword(user, origPassword, password)
  }

  const LogonOrChangePassword = () => {
    if (currentUser.new_password_required) {
      changePassword()
    } else {
      doLogon()
    }
  }

  useEffect(() => {
    if (currentUser.new_password_required) {
      setLoggingIn(false)
      setOrigPassword(password)
      setPassword("")
    }
  }, [currentUser.new_password_required])
  const ref = useRef(null)
  return (
    <div css={styles.LogonWrapper}>
      <Modal
        open={showReset}
        onClose={() => setShowReset(false)}
        header="Einstellungen"
      >
        <RequestResetDialog onClose={() => setShowReset(false)} />
      </Modal>

      <Fade timeout="750ms" nodeRef={ref} in>
        <div css={styles.Logon} ref={ref}>
          <div css={styles.ImageContainer}>
            <ArionImg />
          </div>
          <div css={styles.LogonControls}>
            <TextField
              label="User"
              value={user}
              onChange={(ev) => setUser(ev.currentTarget.value)}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  LogonOrChangePassword()
                }
              }}
              autoFocus
            />
            <Password
              label="Password"
              type="password"
              value={password}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  LogonOrChangePassword()
                }
              }}
              onChange={(ev) => setPassword(ev.currentTarget.value)}
            />
            {currentUser.new_password_required && (
              <Password
                label="Password bestätigen"
                type="password"
                value={verifyPassword}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    LogonOrChangePassword()
                  }
                }}
                onChange={(ev) => setVerifiyPassword(ev.currentTarget.value)}
              />
            )}
            <div css={styles.ErrorContainer}>{error}</div>
            <Button
              css={styles.LogonButton}
              expand
              onClick={
                currentUser.new_password_required ? changePassword : doLogon
              }
            >
              <span css={{ marginRight: "0.3rem" }}>
                {loggingIn ? <SpinnerIcon /> : <Icon icon="right-to-bracket" />}
              </span>
              {"  "}
              {currentUser.new_password_required
                ? "Kennwort ändern"
                : "Anmelden"}
            </Button>
            {allow_reset && (
              <span
                style={{
                  float: "right",
                  color: "#09f",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setShowReset(true)
                }}
              >
                Passwort vergessen?
              </span>
            )}
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default LogonWindow
