import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "zustand/middleware/immer"
import { devtools } from "zustand/middleware"
import request from "superagent"
import to from "await-to-js"

/**
 *
 * @type {usePermissionStore<{
 *    permissions: {},
 *    errors: {},
 *    actions: {
 *      fetchPermission(group, name, int_id): Promise<void>,
 *      fetchValuePermission(group, name, valueName, value): Promise<void>,
 *      fetchAppPermission(group, name): Promise<void>
 *    }
 *  }>}
 */
const usePermissionStore = create(
  immer(
    devtools((set, get) => ({
      permissions: {},
      errors: {},
      actions: {
        reset: () => {
          set((state) => {
            state.permissions = {}
            state.errors = {}
          })
          console.log(get().permissions, get().errors)
        },
        fetchPermission: async (group, name, int_id) => {
          if (get().permissions[`${group}-${name}-${int_id}`]) {
            return
          }
          set((state) => {
            state.permissions[`${group}-${name}-${int_id}`] = -100
          })
          const [err, resp] = await to(
            request.get(`/api/perms/int_id/${group}/${name}/${int_id}`)
          )
          if (err) {
            set((state) => {
              state.errors[`${group}-${name}-${int_id}`] = err
            })
            return
          }
          set((state) => {
            state.permissions[`${group}-${name}-${int_id}`] =
              resp.body.permission
          })
        },
        fetchValuePermission: async (
          group,
          name,
          valueName,
          value,
          opts = {}
        ) => {
          const baseKey = `${group}-${name}-${valueName}-${value}`
          let key = baseKey
          if (opts.exact) {
            key = `${baseKey}-exact`
          }
          if (get().permissions[key]) {
            return
          }
          set((state) => {
            state.permissions[key] = -1
          })
          const [err, resp] = await to(
            request
              .get(`/api/perms/value/${valueName}/${group}/${name}/${value}`)
              .query(opts)
          )
          if (err) {
            set((state) => {
              state.errors[key] = err
            })
            return
          }
          set((state) => {
            state.permissions[key] = resp.body.permission
          })
        },
        fetchAppPermission: async (group, name) => {
          if (get().permissions[`${group}-${name}`] !== undefined) {
            return
          }
          set((state) => {
            state.permissions[`${group}-${name}`] = -100
          })

          const [err, resp] = await to(
            request.get(`/api/perms/app/${group}/${name}`)
          )
          if (err) {
            set((state) => {
              state.errors[`${group}-${name}`] = err
            })
            return
          }
          set((state) => {
            state.permissions[`${group}-${name}`] = resp.body.permission
          })
        }
      }
    }))
  )
)

export default usePermissionStore
