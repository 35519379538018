import React from "react"

const styles = {
  box: {
    padding: "0.72em",
    paddingTop: "0.09em",
    paddingBottom: "0.27em",
    margin: "0.45em",
    marginTop: "1.36em",
    borderRadius: "0.45em",
    whiteSpace: "nowrap",
    border: "1px solid #ccc",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    boxShadow: "0px 0px 0.63em #ddd"
  },
  caption: {
    fontSize: "0.9em",
    color: "#666",
    textShadow: "0.09em 0.09em 0.09em #ccc",
    //marginTop: "-20px",
    position: "absolute",
    top: "-1.18em"
  },
  content: {
    paddingTop: "0.09em",
    paddingRight: "0px",
    paddingBottom: "0.45em",
    flex: 1,
    overflow: "hidden"
  },
  group: {
    padding: "17px",
    margin: "5px",
    whiteSpace: "nowrap"
  }
}

export const Box = ({
  children,
  label,
  rightEl,
  labelStyle = {},
  ...props
}) => {
  let label_el = null
  if (label) {
    label_el = (
      <div style={labelStyle} css={styles.caption}>
        {label}
      </div>
    )
  }
  if (rightEl) {
    label_el = (
      <>
        <div style={labelStyle} css={styles.caption}>
          {label}
        </div>
        <div style={{ position: "absolute", right: "0.45em", top: "-1.18em" }}>
          {rightEl}
        </div>
      </>
    )
  }
  return (
    <div css={styles.box} {...props}>
      {label_el}
      <div css={styles.content}>{children}</div>
    </div>
  )
}

export const Group = ({ children, ...props }) => {
  return (
    <div css={styles.group} {...props}>
      {children}
    </div>
  )
}

export default Box
