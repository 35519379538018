import React, { forwardRef } from "react"
import {
  CSSTransition,
  TransitionGroup as _TransitionGroup
} from "react-transition-group"
import { css } from "@emotion/css"

const visibleStyles = {
  opacity: 1
}

const hiddenStyles = {
  opacity: 0
}
export const TransitionGroup = ({ children, ...props }) => (
  <_TransitionGroup {...props}>{children}</_TransitionGroup>
)

export const Fade = forwardRef(
  ({ children, timeout = "250ms", ...props }, ref) => {
    return (
      <CSSTransition
        nodeRef={ref}
        classNames={{
          appear: css(hiddenStyles),
          appearActive: css({
            ...visibleStyles,
            transition: `all ${timeout}`
          }),
          enter: css(hiddenStyles),
          enterActive: css({
            ...visibleStyles,
            transition: `all ${timeout}`
          }),
          enterDone: css({
            ...visibleStyles
          }),
          exit: css(visibleStyles),
          exitActive: css({
            ...hiddenStyles,
            transition: `all ${timeout}`
          }),
          exitDone: css(hiddenStyles)
        }}
        timeout={500}
        appear
        {...props}
      >
        {children}
      </CSSTransition>
    )
  }
)
