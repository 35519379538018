import useAuthStore from "app/shared/authentication/state"
import { useEffect } from "react"

export const useAuthActions = () => {
  return useAuthStore((state) => state.actions)
}

export const useAuthStatus = () => {
  const { CheckLogin } = useAuthActions()
  useEffect(() => {
    CheckLogin()
  }, [])
  return useAuthStore((state) => state.user.logged_in)
}

export const useAuthError = () => {
  return useAuthStore((state) => state.error)
}

export const useAllowEmailReset = () => {
  return useAuthStore((state) => state.allow_reset)
}

export const useAuthUser = () => {
  return useAuthStore((state) => state.user)
}

export const useCurrentIntID = () => {
  const user = useAuthUser()
  return user.int_id
}

export const useCurrentUserID = () => {
  const user = useAuthUser()
  return user.user_id
}

export const useUserType = () => {
  const user = useAuthUser()
  return user.user_type
}

export const useIsInfoUser = () => {
  const user = useAuthUser()
  return user.user_type === "info"
}
