import React, { useEffect, useRef } from "react"

import { OverlayPanel } from "primereact/overlaypanel"

const OverlayTrigger = React.forwardRef(
  (
    {
      useContextMenu,
      open = false,
      controlled,
      children,
      overlay,
      className,
      id,
      onOpen,
      onClose
    },
    ref
  ) => {
    const innerRef = useRef()
    const targetRef = useRef()
    const op = ref || innerRef
    useEffect(() => {
      if (controlled) {
        if (open) {
          op.current.show(null, targetRef.current)
        } else {
          op.current.hide(null, targetRef.current)
        }
      }
    }, [open, controlled])

    const handleClick = (event) => {
      if (event.shiftKey) {
        return
      }
      if (useContextMenu) {
        event.preventDefault()
      }
      op.current.toggle(event)
      onOpen && onOpen()
    }
    const handleClose = (event) => {
      op.current.hide(event)
      onClose && onClose()
    }
    return (
      <>
        <div
          ref={targetRef}
          css={{ overflow: "hidden" }}
          className={className}
          aria-describedby={id}
          onClick={useContextMenu ? () => handleClose : handleClick}
          onContextMenu={useContextMenu ? handleClick : null}
        >
          {children}
        </div>
        <OverlayPanel ref={op}>{overlay}</OverlayPanel>
      </>
    )
  }
)

export const Overlay = React.forwardRef(
  ({ children, target, open, onHide }, ref) => {
    useEffect(() => {
      if (open) {
        ref.current && ref.current.show(null, target.current)
      } else {
        ref.current && ref.current.hide(null, target.current)
      }
    }, [open])
    return (
      <OverlayPanel onHide={onHide} ref={ref}>
        {children}
      </OverlayPanel>
    )
  }
)

export default OverlayTrigger
