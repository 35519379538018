import {
  BackendFetch,
  I18nextPlugin,
  LanguageDetector,
  LanguageStorage,
  Tolgee,
  withTolgee
} from "@tolgee/i18next"
import i18n from "i18next"
import ICU from "i18next-icu"
import { initReactI18next } from "react-i18next"

const tolgee = Tolgee()
  .use(I18nextPlugin())
  .use(BackendFetch({ prefix: "/static/i18n" }))
  .use(LanguageDetector())
  .use(LanguageStorage())

  .init({
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    availableLanguages: ["de", "en"],
    defaultLanguage: "de"
  })

withTolgee(i18n, tolgee)
  .use(ICU)
  .use(initReactI18next)
  .init({
    lng: "de",
    supportedLngs: ["de", "en"]
  })

export default i18n
