import to from "await-to-js"
import { useEffect } from "react"
import { useState } from "react"
import request from "superagent"

export const useVersion = () => {
  const [version, setVersion] = useState(null)
  useEffect(() => {
    loadVersion().then(setVersion)
  }, [])
  return version
}

const loadVersion = async () => {
  const [err, resp] = await to(request.get(`/api/version`))
  if (err) {
    throw err
  }
  return resp.body
}
