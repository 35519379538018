import Icon, { GlyphIcon } from "app/shared/ui/icons/icons"
import React, { useEffect, useMemo } from "react"
import usePermissionStore from "app/permissions/state"
import { NoPermission } from "app/permissions/util"
import Sperrdatum, { ReadonlyContext, useReadonly } from "app/shared/sperrdatum"
import { LoadingOverlay } from "app/shared/ui"
export { NoPermission } from "app/permissions/util"

export const VisibleOr401 = () => {}

export const VisibleWithAnyPermission = ({ perm, hideOnly, children }) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchPermission
  )
  const permission = usePermissionStore((store) => {
    const perms = []
    for (const p of perm) {
      if (store.permissions[`${p.group}-${p.name}-${p.int_id}`] !== undefined) {
        perms = [...perms, store.permissions[p]]
      }
    }
    return Math.max(...perms)
  })
  useEffect(() => {
    for (const p of perm) {
      loadPermission(p.group, p.name, p.int_id)
    }
  }, [perm])
  if (permission === undefined) {
    return null
  }
  if (permission === -100) {
    return <LoadingOverlay isLoading={true} />
  }
  if (permission < 1) {
    if (hideOnly) {
      return null
    }
    return <NoPermission />
  }
  return children
}

export const VisibleWithPermission = ({
  group,
  name,
  int_id,
  permType,
  hideOnly,
  children,
  ...rest
}) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchPermission
  )
  const permission = usePermissionStore(
    (store) => store.permissions[`${group}-${name}-${int_id}`]
  )
  useEffect(() => {
    loadPermission(group, name, int_id)
  }, [group, name, int_id])
  if (permission === undefined) {
    return null
  }
  if (permission === -100) {
    return <LoadingOverlay isLoading={true} />
  }
  if (permission < permType) {
    if (rest.else) {
      return rest.else
    }
    if (hideOnly) {
      return null
    }

    return <NoPermission />
  }
  return children
}

export const IfCanRead = ({ group, name, int_id, children, ...rest }) => {
  return (
    <VisibleWithPermission
      name={name}
      group={group}
      int_id={int_id}
      permType={1}
      {...rest}
    >
      {children}
    </VisibleWithPermission>
  )
}

export const IfCanWrite = ({ group, name, int_id, children, ...rest }) => {
  return (
    <VisibleWithPermission
      name={name}
      group={group}
      int_id={int_id}
      permType={2}
      {...rest}
    >
      {children}
    </VisibleWithPermission>
  )
}

export const VisibleWithAppPermission = ({
  group,
  name,
  permType = 1,
  hideOnly,
  children,
  ...rest
}) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchAppPermission
  )
  const permission = usePermissionStore(
    (store) => store.permissions[`${group}-${name}`]
  )
  useEffect(() => {
    loadPermission(group, name)
  }, [group, name])
  if (permission === undefined) {
    return null
  }
  if (permission === -100) {
    return <LoadingOverlay isLoading={true} />
  }
  if (permission < permType) {
    if (hideOnly) {
      return null
    }
    if (rest.else) {
      return rest.else
    }
    return <NoPermission />
  }
  return children
}

export const VisibleWithValuePermission = ({
  group,
  name,
  valueName,
  value,
  permType,
  hideOnly,
  children,
  ...rest
}) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchValuePermission
  )
  const permission = usePermissionStore(
    (store) => store.permissions[`${group}-${name}-${valueName}-${value}`]
  )
  useEffect(() => {
    loadPermission(group, name, valueName, value)
  }, [group, name, valueName, value])
  if (permission === undefined) {
    return null
  }
  if (permission < permType) {
    if (hideOnly) {
      return null
    }
    if (rest.else) {
      return rest.else
    }
    return <NoPermission />
  }
  return children
}

export const IfCanReadValue = ({ children, ...props }) => {
  return (
    <VisibleWithValuePermission {...props} permType={1}>
      {children}
    </VisibleWithValuePermission>
  )
}

export const IfCanWriteValue = ({ children, ...props }) => {
  return (
    <VisibleWithValuePermission {...props} permType={2}>
      {children}
    </VisibleWithValuePermission>
  )
}

export const IfCanWriteAllValues = ({ children }) => {
  return children
}

export const usePermission = (group, name, int_id) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchPermission
  )
  const permission = usePermissionStore(
    (store) => store.permissions[`${group}-${name}-${int_id}`]
  )

  useEffect(() => {
    if (int_id === undefined) {
      return
    }
    loadPermission(group, name, int_id)
  }, [group, name, int_id])
  if (permission === undefined) {
    return -100
  }
  return permission
}

export const useSetPermission = (group, name, int_id) => {
  const permission = usePermission(group, name, int_id)
  const loading = permission === -100 || permission === undefined
  const set = permission !== -1 && !loading
  return { permission: set ? permission : undefined, loading, set }
}

export const useValuePermission = (
  group,
  name,
  valueName,
  value,
  opts = {}
) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchValuePermission
  )
  const baseKey = `${group}-${name}-${valueName}-${value}`
  let key = baseKey
  if (opts.exact) {
    key = `${baseKey}-exact`
  }
  const permission = usePermissionStore((store) => store.permissions[key])
  useEffect(() => {
    loadPermission(group, name, valueName, value, opts)
  }, [group, name, valueName, value, opts.exact])
  return permission
}

export const useAppPermission = (group, name) => {
  const loadPermission = usePermissionStore(
    (store) => store.actions.fetchAppPermission
  )
  const permission = usePermissionStore(
    (store) => store.permissions[`${group}-${name}`]
  )
  useEffect(() => {
    loadPermission(group, name)
  }, [])
  return permission
}

export const PersPermissionHandler = ({
  group,
  name,
  int_id,
  datum,
  children,
  skipParent,
  onlySet
}) => {
  const permission = usePermission(group, name, int_id)

  const readOnly = useReadonly({ int_id, datum })
  const value = useMemo(() => {
    if (readOnly && !skipParent) {
      return true
    }
    if (onlySet && permission === -1) {
      return false
    }
    return permission < 2
  }, [permission, readOnly])
  if (permission === -100) {
    return <LoadingOverlay isLoading={true} />
  }
  if (permission === 0) {
    return <NoPermission noLink />
  }
  return (
    <ReadonlyContext.Provider value={value}>
      {datum && <Sperrdatum int_id={int_id} datum={datum} />}
      {children}
    </ReadonlyContext.Provider>
  )
}
