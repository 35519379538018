import { createContext, useContext } from "react"

export const ToastContext = createContext({
  showToast: ({ severity, summary, detail, content, icon, closeIcon }) => {},
  hideToast: () => {}
})

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider")
  }
  return { show: context.showToast, hide: context.hideToast }
}
