import PropTypes from "prop-types"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react"

import { DataTable as NTable } from "primereact/datatable"
import { Column as NColumn } from "primereact/column"
import { ContextMenuWithRef, MenuItem } from "./contextmenu"
import Button from "./button"
import Icon from "./icons/icons"
import { useSettingsStore } from "app/settings/appsettings"
import useMeasure from "react-use-measure"

export const Column = NColumn
export const Cell = ({ children }) => <>{children}</>
export const HeaderCell = Cell

export const Table = forwardRef(
  ({ noCompat = true, children, ...props }, ref) => {
    if (noCompat) {
      return (
        <NoCompatTable ref={ref} {...props}>
          {children}
        </NoCompatTable>
      )
    }
    return <CompatTable {...props}>{children}</CompatTable>
  }
)

const NoCompatTable = forwardRef(
  (
    {
      children,
      width,
      height,
      rowHeight = 30,
      virtualScroll,
      scrollHeight = "flex",
      scrollable = true,
      editMode = "cell",
      stripedRows = true,
      loading,
      customRestoreState,
      ...props
    },
    ref
  ) => {
    const { fontSize } = useSettingsStore()
    const activeRowSize = useMemo(() => {
      const mod = parseInt(fontSize) / 11
      return parseInt(rowHeight * mod)
    }, [fontSize])
    const [sizeRef, bounds] = useMeasure({ debounce: 25 })

    const scrollHeightC = useMemo(() => {
      if (scrollHeight === "flex") {
        return `${parseInt(bounds.height)}px`
      }
      return scrollHeight
    }, [scrollHeight, bounds])
    const virtualScrollerOptions = useMemo(() => {
      if (virtualScroll) {
        return {
          itemSize: activeRowSize || 30,
          autoSize: false,
          scrollHeight: scrollHeightC
        }
      }
      return undefined
    }, [virtualScroll, activeRowSize, scrollHeightC])
    const cssStyle = useMemo(() => {
      return {
        width: width || "100%",
        height: height || "100%",
        borderLeft: "0.5px solid #ddd",
        "tr > td": {
          height: activeRowSize,
          padding: "0em 0.5em",
          borderRight: "0.5px solid #ddd"
        },
        "tr > th": {
          borderRight: "0.5px solid #ddd"
        }
      }
    }, [width, height, activeRowSize])
    const emptyMessage = useMemo(() => {
      return <div />
    }, [])

    const innerRef = useRef()
    useImperativeHandle(ref, () => innerRef.current)

    useEffect(() => {
      if (innerRef.current && customRestoreState && !loading) {
        innerRef.current.restoreTableState(customRestoreState())
      }
    }, [loading])

    // BUGFIX: scrollheight flex fix
    // const [reload, setReload] = useState("t-0")
    // useEffect(() => {
    //   setTimeout(() => {
    //     setReload((r) => "t-1")
    //   }, 0)
    // }, [])
    return (
      <div css={{ width: "100%", height: "100%", flex: 1 }} ref={sizeRef}>
        <NTable
          //key={reload}
          ref={innerRef}
          css={cssStyle}
          loading={loading}
          editMode={editMode}
          stripedRows={stripedRows}
          scrollable={scrollable}
          scrollHeight={scrollHeight}
          virtualScrollerOptions={virtualScrollerOptions}
          emptyMessage={emptyMessage}
          columnResizeMode="fit"
          customRestoreState={customRestoreState}
          {...props}
          //pt={{ wrapper: { style: { overflow: "visible" } } }}
        >
          {children}
        </NTable>
      </div>
    )
  }
)

export const ColumnFilterButton = ({ available, filter, toggleFilter }) => {
  const ref = useRef()
  const menuItems = useMemo(() => {
    const groups = available.reduce((acc, f) => {
      if (!acc[f.group]) {
        acc[f.group] = []
      }
      acc[f.group].push(f)
      return acc
    }, {})
    return Object.entries(groups)
      .sort(([group, items], [groupb, itemsb]) => {
        if (group === "undefined" && groupb !== "undefined") {
          return -1
        } else if (group !== "undefined" && groupb === "undefined") {
          return 1
        } else if (group === "undefined" && groupb === "undefined") {
          return 0
        }
        return group.localeCompare(groupb)
      })
      .map(([group, items]) => {
        if (group === "undefined") {
          return items.map((f) => (
            <MenuItem
              key={f.field}
              label={f.label}
              checked={filter[f.field]}
              onClick={() => {
                toggleFilter(f.field)
              }}
            />
          ))
        }
        return (
          <MenuItem key={group} label={group}>
            {items.map((f) => (
              <MenuItem
                key={f.field}
                label={f.label}
                checked={filter[f.field]}
                onClick={() => {
                  toggleFilter(f.field)
                }}
              />
            ))}
          </MenuItem>
        )
      })
  }, [filter, available])

  return (
    <>
      <ContextMenuWithRef ref={ref} items={menuItems}></ContextMenuWithRef>
      <Button
        onClick={(e) => {
          e.preventDefault()
          ref.current.show(e)
        }}
      >
        <Icon
          icon={"table-layout"}
          css={{
            color: "#999",
            cursor: "pointer",
            marginLeft: "2px",
            "&:hover": {
              color: "#0cf"
            }
          }}
        />
      </Button>
    </>
  )
}

/*
 * CompatTable ist Kompatibel mit
 * */
export const CompatTable = ({
  data,
  value = [],
  children,
  width,
  height,
  rowHeight,
  selected,
  selection,
  onRowClick,
  style,
  flex,
  isSelected,
  selectionMode = "single",
  virtualScroll,
  disableAlternatingRows,
  emptyMessage,
  noCompat,
  stripedRows = true,
  ...props
}) => {
  //console.warn("Compat Table ist deprecated, upgrade zu noCompat mit neuer api")
  const { fontSize } = useSettingsStore()

  const activeRowSize = useMemo(() => {
    const mod = parseInt(fontSize) / 11
    return rowHeight * mod
  }, [fontSize])

  const [lselected, setLSelected] = useState([])
  const selected1 = useMemo(() => {
    if (selected !== undefined && selected !== null && !selected.length) {
      return data[selected] || value[selected]
    }
    if (!isSelected) {
      return [...(data || value || [])].filter((r, i) => {
        if (selected && !selected.indexOf) {
          return i === selected
        }
        return (selected || []).indexOf(i) !== -1 ? true : false
      })
    }
    return [...(data || value)].filter((r, i) => !!isSelected(i))
  }, [isSelected, data, value, selected])
  const scrollHeight = useMemo(() => {
    if (flex) {
      return "flex"
    }
    if (height == "100%") {
      return "flex"
    }
    return height
  }, [flex, height])
  const cssStyle = useMemo(() => {
    return {
      width: width || "100%",
      height: height || "100%",
      borderLeft: "0.5px solid #ddd",
      "tr > td": {
        height: activeRowSize,
        padding: "0em 0.5em",
        borderRight: "0.5px solid #ddd"
      },
      "tr > th": {
        borderRight: "0.5px solid #ddd"
      }
    }
  }, [width, height, activeRowSize])
  // console.trace(
  //   "Compat Table ist deprecated, upgrade zu noCompat mit neuer api"
  // )
  return (
    <NTable
      value={data || value}
      tableStyle={{
        ...style
      }}
      css={cssStyle}
      scrollable
      scrollHeight={scrollHeight}
      virtualScrollerOptions={
        virtualScroll ? { itemSize: activeRowSize || 30 } : undefined
      }
      selectionMode={selectionMode || "multi"}
      selection={selection || selected1 || lselected}
      onSelectionChange={(e) => {
        // onRowClick &&
        //   onRowClick(e, (value || data || []).indexOf(e.value), e.value)
        setLSelected(e.value)
        console.log(e)
      }}
      onRowClick={(data) =>
        onRowClick(data.originalEvent, data.index, data.data)
      }
      stripedRows={disableAlternatingRows ? false : true}
      emptyMessage={emptyMessage || <div />}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          header:
            child.props.header ||
            child.props.headerRenderer ||
            child.props.label,
          style: {
            width: child.props.flexGrow ? child.props.width : child.props.width,
            minWidth: child.props.width,
            whiteSpace: "nowrap",
            overflow: "hidden",

            ...child.props.style
          },
          body: child.props.cellRenderer
            ? (rowData, props) => {
                return child.props.cellRenderer({ rowData, ...props })
              }
            : child.props.body
        })
      )}
    </NTable>
  )
}
