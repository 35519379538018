import { createRef } from "react"
import { Fade } from "app/shared/ui/transition"
import { SpinnerIcon } from "app/shared/ui/icons/icons"

const styles = {
  overlay: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#33333399",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#ccc",
    fontSize: "22px",
    zIndex: 1000
  }
}

export const Overlay = ({ visible }) => {
  const ref = createRef()
  return (
    <Fade nodeRef={ref} in={visible} unmountOnExit>
      <div ref={ref} css={styles.overlay}>
        <SpinnerIcon />
      </div>
    </Fade>
  )
}
