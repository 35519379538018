import Icon from "app/shared/ui/icons/icons"
import React from "react"

export const NoPermission = ({ noLink }) => {
  return (
    <div
      css={{
        textShadow: "1px 1px 1px #c9c9c9",
        pointerEvents: "none"
      }}
    >
      <div
        css={{
          color: "#d0d0d0",
          textAlign: "center",
          paddingTop: "15%",
          fontSize: "90px",
          width: "100%",
          height: "100%",
          fontWeight: "bold"
        }}
      >
        <Icon icon="lock" />
      </div>
      <div css={{ color: "#d0d0d0", textAlign: "center", width: "100%" }}>
        Keine Berechtigung
      </div>
      {!noLink && (
        <div css={{ color: "#d0d0d0", textAlign: "center", width: "100%" }}>
          <a href="/">Zurück</a>
        </div>
      )}
    </div>
  )
}
