import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import GlyphIcon, { SpinnerIcon } from "./icons/icons"

import Datetime from "../dateutil/index"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { cx } from "@emotion/css"
import { useReadonly } from "app/shared/sperrdatum"
import { InputNumber } from "primereact/inputnumber"
import { Password as PPassword } from "primereact/password"
import { css } from "@emotion/react"
import { isNil } from "lodash"
export const Switch = ({ disabled, ...rest }) => {
  const rO = useReadonly({ readOnly: disabled })

  return <InputSwitch {...rest} disabled={rO} />
}

Switch.defaultProps = {
  size: "small"
}

const styles = {
  textField: {
    fontSize: "1em",
    flex: 1,
    width: "100%",
    backgroundColor: "transparent"
  },
  align: {
    center: {
      textAlign: "center"
    },
    left: {
      textAlign: "left"
    },
    right: {
      textAlign: "right"
    }
  },
  input: {
    width: "100%"
  },
  inputLabel: {
    fontSize: "0.9em",
    color: "#666"
  },
  charsleft: {
    fontSize: "0.9em",
    cursor: "pointer",
    color: "#ccc",
    position: "absolute",
    bottom: "0.45em",
    right: "0.8em"
  },
  charsleftWarn: {
    fontSize: "0.9em",
    cursor: "pointer",
    color: "#e90",
    position: "absolute",
    bottom: "0.45em",
    right: "0.8em",
    fontWeight: "bold"
  },
  textAreaContainer: {
    display: "flex",
    flex: "1",

    position: "relative"
  },
  textArea: {
    fontFamily: "RobotoArion",
    fontSize: "1em",
    borderRadius: "0.45em",
    border: "1px solid #ccc",
    outline: "none",
    flex: "1"
  },
  textAreaClearIcon: {
    fontSize: "0.9em",
    cursor: "pointer",
    color: "#ccc",
    position: "absolute",
    bottom: "0.45em",
    right: "0.8em"
  },
  searchContainer: {
    height: "1.8em",
    backgroundColor: "#f9f9f9",
    fontWeight: "bold",
    position: "relative"
  },
  searchField: {
    borderRadius: "0.9em",
    border: "1px solid #ccc",
    width: "100%",
    paddingLeft: "1.8em",
    maxHeight: "1.8em",
    fontWeight: "normal",
    fontSize: "1em"
  },
  searchIcon: {
    position: "absolute",
    left: "0.45em",
    top: "0.27em",
    color: "#ccc"
  }
}
//

export const TextField = React.forwardRef(
  (
    {
      value,
      onChange,
      cell,
      hidden,
      className,
      label = null,
      readOnly,
      flex,
      ...rest
    },
    ref
  ) => {
    const rO = useReadonly({ readOnly: readOnly })

    const curatedValue = value === null ? "" : value
    if (label) {
      return (
        <TextFieldWithLabel
          value={curatedValue}
          onChange={onChange}
          className={className}
          label={label}
          flex={flex}
          readOnly={rO}
          {...rest}
        />
      )
    }
    return (
      <InputText
        ref={ref}
        value={curatedValue}
        className={cx("w-full", className, rO ? "p-disabled" : "")}
        onChange={onChange}
        {...rest}
        readOnly={rO}
        css={
          hidden || cell
            ? { border: "none", backgroundColor: "transparent" }
            : null
        }
      />
    )
  }
)

export const VLabel = ({
  label,
  children,
  help,
  className,
  flex,
  ...props
}) => {
  return (
    <div className="flex flex-column gap-0" css={flex ? { flex: 1 } : null}>
      <label
        className={cx("flex flex-column gap-0", className)}
        css={{ fontSize: "0.9em", color: "#666" }}
        {...props}
      >
        {label}
        {children}
      </label>
      {help && <small css={{ fontSize: "0.8em", color: "#666" }}>{help}</small>}
    </div>
  )
}

export const HLabel = ({
  label,
  children,
  help,
  className,
  flex,
  reverse,
  ...props
}) => {
  return (
    <div
      className={cx("flex flex-column gap-0", className)}
      css={[flex ? { flex: 1 } : null, { padding: "0.8rem", paddingLeft: "0" }]}
    >
      <label
        className={cx("flex flex-row gap-2", className)}
        css={{ fontSize: "0.9em", color: "#666", alignItems: "center" }}
        {...props}
      >
        {children}
        {label}
      </label>
      {help && <small css={{ fontSize: "0.8em", color: "#666" }}>{help}</small>}
    </div>
  )
}
//
// export const FieldWithLabel = ({ label, help, flex, children }) => {
//   return (
//     <div className="flex flex-column gap-0" css={flex ? { flex: 1 } : null}>
//       <label
//         css={{ fontSize: "0.9em", color: "#666" }}
//         className="flex flex-column gap-0"
//       >
//         {label}
//         {children}
//       </label>
//       {help && <small css={{ fontSize: "0.8em", color: "#666" }}>{help}</small>}
//     </div>
//   )
// }

export const Password = React.forwardRef(
  (
    {
      value,
      onChange,
      label,
      help,
      flex,
      toggleMask = true,
      feedback = false,
      ...props
    },
    ref
  ) => {
    if (label) {
      return (
        <WithLabel label={label} help={help} flex={flex}>
          <PPassword
            ref={ref}
            feedback={feedback}
            toggleMask={toggleMask}
            value={value}
            onChange={onChange}
            {...props}
          />
        </WithLabel>
      )
    }
    return <PPassword ref={ref} value={value} onChange={onChange} {...props} />
  }
)

export const WithLabel = ({ label, children, help, flex }) => {
  return (
    <div className="flex flex-column gap-0" css={flex ? { flex: 1 } : null}>
      <label
        css={{ fontSize: "0.9em", color: "#666" }}
        className="flex flex-column gap-0"
      >
        {label}
        {children}
      </label>
      {help && <small css={{ fontSize: "0.8em", color: "#666" }}>{help}</small>}
    </div>
  )
}

export const TextFieldWithLabel = React.forwardRef(
  (
    {
      value,
      onChange,
      cell,
      hidden,
      className,
      label,
      help,
      readOnly,
      flex,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="flex flex-column" css={flex ? { flex: 1 } : null}>
        <label
          css={{ fontSize: "0.9em", color: "#666", gap: "0.2rem" }}
          className="flex flex-column"
        >
          {label}
          <InputText
            ref={ref}
            value={value}
            className={cx("w-full", className, readOnly ? "p-disabled" : "")}
            onChange={onChange}
            readOnly={readOnly}
            {...rest}
            css={[
              hidden
                ? { border: "none", backgroundColor: "transparent" }
                : null,
              flex ? { flex: 1 } : null
            ]}
          />
        </label>
        {help && (
          <small css={{ fontSize: "0.8em", color: "#666" }}>{help}</small>
        )}
      </div>
    )
  }
)

TextField.displayName = "TextField"

TextField.defaultProps = {
  variant: "standard",
  size: "small"
}

export const NumberField = ({
  value,
  onChange,
  places = 0,
  className,
  readOnly,
  label,
  help,
  flex,
  hidden,
  width,
  ...props
}) => {
  const rO = useReadonly({ readOnly: readOnly })
  const Comp = (
    <InputNumber
      css={[{ minWidth: "0px" }, flex ? { flex: 1 } : null]}
      inputStyle={
        hidden
          ? {
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              boxShadow: "none",
              color: "inherit",
              minWidth: 0,
              width: width
            }
          : { minWidth: 0, width: width }
      }
      className={[className, rO ? "p-disabled" : ""]}
      readOnly={rO}
      onValueChange={(e) => {
        if (!e.originalEvent) {
          return
        }
        onChange(e, e.value)
      }}
      maxFractionDigits={places}
      minFractionDigits={places}
      useGrouping={false}
      value={isNil(value) ? null : value}
      {...props}
    />
  )
  if (label) {
    return (
      <VLabel label={label} help={help} flex={flex}>
        {Comp}
      </VLabel>
    )
  }
  return Comp
}

export const NumberField2 = ({ value, onChange, places, ...props }) => {
  const parseValue = (ev) => {
    let pos = ev.target.selectionStart
    let cvalue = ev.currentTarget.value

    if (cvalue == "") {
      onChange(ev, null)
      return
    }

    // Default value
    let pValue = parseFloat(cvalue.replace(",", "."))
      .toFixed(places)
      .replace(".", ",")
    if (value && value[pos] == "," && cvalue.indexOf(",") == -1) {
      pValue = value
    }

    //Cursor Position anpassen
    ev.currentTarget.value = pValue

    onChange(ev, pValue.replace(",", "."))
    ev.target.selectionStart = pos
    ev.target.selectionEnd = pos
  }
  return (
    <TextField
      value={
        (value !== null &&
          value !== undefined &&
          parseFloat(value).toFixed(places).replace(".", ",")) ||
        ""
      }
      onChange={parseValue}
      {...props}
    />
  )
}

const D1900 = Datetime.fromValues(1900, 0, 1).toJSDate()

export const TimeField = ({ value, onChange, onBlur, ...props }) => {
  const [cvalue, setValue] = useState(value)
  useEffect(() => {
    setValue(value)
  }, [value])
  return (
    <RawTimeField
      {...props}
      value={(cvalue && Datetime.fromJSDate(cvalue).formatTimeShort24()) || ""}
      onBlur={
        onBlur
          ? (ev) => {
              if (ev.currentTarget.value == "") {
                onBlur && onBlur(ev, null, !value)

                return
              }
              const time = Datetime.fromTime(ev.currentTarget.value)
              const newValue = Datetime.fromJSDate(value || D1900).set({
                hour: time.hour(),
                minute: time.minute()
              })
              const oldValue = Datetime.fromJSDate(value).formatTimeShort24()
              console.log(oldValue, newValue.formatTimeShort24())
              onBlur &&
                onBlur(
                  ev,
                  newValue.toJSDate(),
                  oldValue === newValue.formatTimeShort24()
                )
            }
          : undefined
      }
      onChange={(ev) => {
        if (ev.currentTarget.value == "") {
          setValue(null)
          onChange && onChange(ev, null)

          return
        }
        const time = Datetime.fromTime(ev.currentTarget.value)
        const newValue = Datetime.fromJSDate(value || D1900).set({
          hour: time.hour(),
          minute: time.minute()
        })
        setValue(newValue.toJSDate())
        onChange && onChange(ev, newValue.toJSDate())
      }}
    />
  )
}

export const RawTimeField = ({ value, onChange, onBlur, ...props }) => {
  const parseValue = (cb) => (ev) => {
    let pos = ev.target.selectionStart
    if (pos === 0) {
      return cb(ev)
    }
    let cvalue = ev.currentTarget.value
    if (cvalue == "") {
      cb(ev)
      return
    }

    if (cvalue[pos] == ":" && cvalue[pos - 1] != ":") {
      cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 2)
    } else {
      cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 1)
    }

    // Stunden und minuten ermitteln
    const pattern = /^([0-9]{1,2}):?([0-9]{0,2})/
    const results = cvalue.match(pattern)

    // Default value
    let pValue = "00:00"
    if (results) {
      // Stunden normalisieren - Maximal 23
      if (parseInt(results[1]) > 23) {
        if (results[1][0] == "2") {
          results[1] = "20"
        } else {
          results[1] = "23"
        }
      }

      // Minuten Normalisieren - maximal 59
      if (parseInt((results[2] + "00").slice(0, 2)) > 59) {
        results[2] = "00"
      }

      pValue = `${(results[1] + "00").slice(0, 2)}:${(results[2] + "00").slice(
        0,
        2
      )}`
    }

    //Cursor Position anpassen

    if (pValue[pos - 1] == ":" && cvalue[pos - 1] != ":") {
      // beim eintippen
      pos = pos + 1
    }
    ev.currentTarget.value = pValue
    ev.target.selectionStart = pos
    ev.target.selectionEnd = pos
    cb(ev)
  }
  return (
    <TextField
      {...props}
      value={value}
      onChange={onChange && parseValue(onChange)}
      onBlur={onBlur && parseValue(onBlur)}
    />
  )
}

export const DateField = React.forwardRef(
  ({ value, onChange, ...props }, ref) => {
    return (
      <RawDateField
        ref={ref}
        value={Datetime.fromJSDate(value).formatDateShort()}
        onChange={(ev) => {
          const date = Datetime.fromDate(ev.currentTarget.value)
          if (!date.isValid()) {
            onChange(ev, value)
            return
          }
          onChange(ev, date.toJSDate())
        }}
        {...props}
      />
    )
  }
)

export const RawDateField = React.forwardRef(
  ({ value, onChange, ...props }, ref) => {
    const parseValue = (ev) => {
      let cvalue = ev.currentTarget.value
      if (cvalue == null) {
        onChange(ev)
        return
      }
      let pos = ev.target.selectionStart
      if (cvalue[pos] == "." && cvalue[pos - 1] != ".") {
        cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 2)
      } else {
        cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 1)
      }
      // Tag Monat und Jahr ermitteln
      const pattern = /^([0-9]{1,2})\.?([0-9]{0,2})\.?([0-9]{0,4})/
      const results = cvalue.match(pattern)

      // Default value
      let pValue = "01.01.0000"
      if (results) {
        // Tag normalisieren - Maximal 31
        if (parseInt(results[1].slice(0, 2)) > 31) {
          results[1] = "31"
        } else if (parseInt(results[1]) == 0) {
          results[1] = "01"
        }

        // Monat Normalisieren - maximal 12
        if (parseInt((results[2] + "00").slice(0, 2)) > 12) {
          results[2] = "12"
        } else if (parseInt(results[2]) == 0) {
          results[2] = "01"
        }

        if (parseInt(results[3]) == 0) {
          results[3] = "0000"
        }

        pValue = `${(results[1] + "01").slice(0, 2)}.${(
          results[2] + "01"
        ).slice(0, 2)}.${(results[3] + "0000").slice(0, 4)}`
      }

      //Cursor Position anpassen
      if (pValue[pos - 1] == "." && cvalue[pos - 1] != ".") {
        // beim eintippen
        pos = pos + 1
      }
      ev.currentTarget.value = pValue
      ev.target.selectionStart = pos
      ev.target.selectionEnd = pos
      onChange(ev)
    }
    return (
      <TextField ref={ref} value={value} onChange={parseValue} {...props} />
    )
  }
)

export const Input = ({
  style,
  labelWidth,
  labelAlign,
  labelColor,
  block,
  onFocus,
  onBlur,
  onMouseOver,
  onMouseLeave,
  reverse,
  label,
  children
}) => {
  let labelStyle = { display: "inline-block" }
  if (labelWidth) {
    labelStyle.width = labelWidth
  }
  if (labelAlign) {
    labelStyle.textAlign = labelAlign
  }
  if (block) {
    labelStyle.display = "block"
  }
  if (labelColor) {
    labelStyle.color = labelColor
  }
  return (
    <div css={[styles.input, style]}>
      <label css={styles.inputLabel}>
        {!reverse ? (
          <span css={[styles.labelStyle, labelStyle]}>{label}</span>
        ) : null}
        {React.cloneElement(children, {
          onMouseOver: onMouseOver,
          onMouseLeave: onMouseLeave,
          onFocus: onFocus,
          onBlur: onBlur
        })}
        {reverse ? (
          <span css={[styles.labelStyle, labelStyle]}> {label}</span>
        ) : null}
      </label>
    </div>
  )
}

export default Input

export const SearchField = React.memo(
  ({ setSearchText, isSearching, ...props }) => {
    const { containerStyle, ...other } = props
    return (
      <div style={containerStyle} css={styles.searchContainer}>
        {isSearching ? (
          <SpinnerIcon css={styles.searchIcon} />
        ) : (
          <GlyphIcon icon="search" css={styles.searchIcon} />
        )}
        <input
          onChange={(ev) => {
            setSearchText(ev.currentTarget.value)
          }}
          {...other}
          css={styles.searchField}
          type="search"
        />
      </div>
    )
  }
)

SearchField.propTypes = {
  setSearchText: PropTypes.func.isRequired
}

export const TextArea = ({
  onFocus,
  onMouseOver,
  onMouseLeave,
  style,
  allowClear,
  onClear,
  value,
  maxLen,
  onChange,
  ...props
}) => {
  const [state, setState] = useState({ active: false, hover: false })
  const textArea = useRef(null)

  useEffect(() => {
    if (onFocus) {
      onFocus(state.active)
    }
  }, [state.active, onFocus])

  const handleFocus = (event) => {
    setState((prevState) => ({ ...prevState, active: true }))
  }

  const handleBlur = (event) => {
    setState((prevState) => ({ ...prevState, active: false }))
  }

  const handleMouseOver = (event) => {
    setState((prevState) => ({ ...prevState, hover: true }))
  }

  const handleMouseLeave = (event) => {
    setState((prevState) => ({ ...prevState, hover: false }))
  }

  const isClearable = () => {
    return allowClear && value && value.trim() ? true : false
  }

  const handleClear = () => {
    onClear()
    textArea.current.focus()
  }

  const charactersLeft = () => {
    if (maxLen) {
      let remaining = maxLen - value.length
      if (remaining < 10) {
        return <span css={styles.charsleftWarn}>{remaining}</span>
      }
      return <span css={styles.charsleft}>{remaining}</span>
    }
    return null
  }

  const handleChange = (ev) => {
    let val = ev.currentTarget.value
    if (maxLen) {
      return onChange(val.substring(0, maxLen))
    }
    return onChange(val)
  }

  return (
    <div css={styles.textAreaContainer} style={style}>
      <textarea
        ref={textArea}
        {...props}
        css={styles.textArea}
        value={value || ""}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {isClearable() && (
        <span css={styles.textAreaClearIcon} onClick={handleClear}>
          <GlyphIcon icon="delete" />
        </span>
      )}
      {charactersLeft()}
    </div>
  )
}

export class TextAreaOLD extends React.Component {
  static propTypes = {
    onFocus: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    style: PropTypes.object,
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
    value: PropTypes.string,
    maxLen: PropTypes.number,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = { active: false, hover: false }
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onClear = this.onClear.bind(this)
    this.isClearable = this.isClearable.bind(this)
    this.charactersLeft = this.charactersLeft.bind(this)
    this.textArea = React.createRef()
  }

  onFocus(event) {
    this.setState({ active: true })
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  onBlur(event) {
    this.setState({ active: false })
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  onMouseOver(event) {
    this.setState({ hover: true })
    if (this.props.onMouseOver) {
      this.props.onMouseOver(event)
    }
  }

  onMouseLeave(event) {
    this.setState({ hover: false })
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event)
    }
  }

  isClearable() {
    return this.props.allowClear && this.props.value && this.props.value.trim()
      ? true
      : false
  }

  onClear() {
    this.props.onClear()
    this.textArea && this.textArea.focus()
  }

  charactersLeft() {
    if (this.props.maxLen) {
      let remaining = this.props.maxLen - this.props.value.length
      if (remaining < 10) {
        return (
          <span css={styles.charsleftWarn}>
            {this.props.maxLen - this.props.value.length}
          </span>
        )
      }
      return (
        <span css={styles.charsleft}>
          {this.props.maxLen - this.props.value.length}
        </span>
      )
    }
    return null
  }

  onChange(ev) {
    let val = ev.currentTarget.value
    if (this.props.withEvent) {
      if (this.props.maxLen) {
        return this.props.onChange(ev, val.substring(0, this.props.maxLen))
      }
      return this.props.onChange(ev, val)
    }
    if (this.props.maxLen) {
      return this.props.onChange(val.substring(0, this.props.maxLen))
    }
    return this.props.onChange(val)
  }

  // onChange(ev) {
  //   let val = ev.currentTarget.value
  //   if (this.props.maxLen) {
  //     return this.props.onChange(val.substring(0, this.props.maxLen))
  //   }
  //   return this.props.onChange(val)
  // }

  render() {
    let {
      onChange,
      containerStyle,
      value,
      onClear,
      allowClear,
      maxLen,
      ...props
    } = this.props
    return (
      <div css={styles.textAreaContainer} style={containerStyle}>
        <textarea
          ref={this.textArea}
          {...props}
          css={styles.textArea}
          value={value || ""}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        {this.isClearable() && (
          <span css={styles.textAreaClearIcon} onClick={this.onClear}>
            <GlyphIcon icon="delete" />
          </span>
        )}
        {this.charactersLeft()}
      </div>
    )
  }
}

export const FloatFormattingField = NumberField
