import { ContextMenu as PContextMenu } from "primereact/contextmenu"
import Button from "app/shared/ui/button"
import { Children, forwardRef, memo, useMemo, useRef } from "react"
import { Menu } from "primereact/menu"
import { cx } from "@emotion/css"
import Icon from "app/shared/ui/icons/icons"
import { usePermission } from "app/permissions/permissions"

export const ContextMenu = memo(({ items, children, pt, flat = false }) => {
  const cm = useRef()
  const itemsModel = useMemo(() => {
    return ItemsToModels(Children.toArray(items))
  }, [items])

  if (flat) {
    return (
      <>
        <Menu pt={pt} model={itemsModel} ref={cm} popup />
        <span
          css={{ cursor: "context-menu" }}
          onContextMenu={(e) => {
            cm.current.show(e)
            e.preventDefault()
          }}
        >
          {children}
        </span>
      </>
    )
  }
  return (
    <>
      <PContextMenu pt={pt} model={itemsModel} ref={cm} />
      <span
        css={{ cursor: "context-menu" }}
        onContextMenu={(e) => cm.current.show(e)}
      >
        {children}
      </span>
    </>
  )
})

export const ContextMenuWithRef = forwardRef(
  ({ items, children, convert = true, flat = false, ...rest }, cm) => {
    const itemsModel = useMemo(() => {
      if (!convert) {
        return items.map((i) => ({
          ...i,
          template: i.template || RenderMenuItem,
          items:
            i.items &&
            i.items.map((i) => ({
              ...i,
              template: i.template || RenderMenuItem
            }))
        }))
      }
      return ItemsToModels(Children.toArray(items))
    }, [items])

    if (flat) {
      return (
        <>
          <Menu model={itemsModel} ref={cm} popup />
          {children}
        </>
      )
    }
    return (
      <>
        <PContextMenu model={itemsModel} ref={cm} {...rest} />
        {children}
      </>
    )
  }
)

export const PopUpMenu = memo(({ model, children, label, tooltip }) => {
  const menu = useRef(null)
  const buttonRef = useRef(null)
  const show = () => {
    menu.current && menu.current.show({ currentTarget: buttonRef.current })
  }
  const items = useMemo(() => {
    return ItemsToModels(Children.toArray(children), show)
  }, [children])
  return (
    <span>
      <Menu model={items} popup ref={menu} />
      <Button
        tooltip={tooltip}
        ref={buttonRef}
        onClick={(e) => menu.current.show(e)}
      >
        {label}
      </Button>
    </span>
  )
})

export const CustomPopUpMenu = forwardRef(({ items, label, children }, ref) => {
  const model = useMemo(() => {
    return ItemsToModels(Children.toArray(items), () => {})
  }, [items])
  return (
    <>
      <Menu model={model} popup ref={ref} />
      {children}
    </>
  )
})

const ItemsToModels = (items, show) => {
  return items.map((i) => ItemToModel(i, show))
}

const ItemToModel = (item, show) => {
  if (item.props.separator) {
    return { separator: true }
  }
  return {
    label: item.props.label,
    template: item.props.template || RenderMenuItem,
    command: (ev) => {
      item.props.onClick && item.props.onClick(ev.originalEvent, ev.item)
    },
    visible: item.props.visible,
    disabled: item.props.disabled,
    //svgIcon: item.props.icon,
    icon: item.props.icon,
    checked: item.props.checked,
    items: item.props.children
      ? ItemsToModels(Children.toArray(item.props.children), show)
      : undefined
  }
}

const RenderMenuItem = (item, options) => {
  return (
    <div className="p-menuitem-content">
      <button
        onClick={(e) => options.onClick && options.onClick(e)}
        className={cx(
          options.className,
          "w-full p-link flex align-items-center",
          {
            "p-disabled": item.disabled
          }
        )}
        data-no-dnd="true"
        tabIndex={item.disabled ? -1 : 0}
        disabled={item.disabled}
      >
        <div
          className="flex flex-row"
          css={{
            lineHeight: "0.875rem",
            fontSize: "0.875rem",
            color: "#666",
            width: "100%"
          }}
        >
          {item.svgIcon && <span css={{ width: "13px" }}>{item.svgIcon}</span>}
          {(item.icon && item.icon) || null}
          <span css={{ flex: "1", whiteSpace: "nowrap" }} className="text-sm">
            {item.label}
          </span>
          {item.checked && (
            <span css={{ width: "20px" }}>
              <Icon icon={"check"} />
            </span>
          )}
          {item.items && (
            <span css={{ width: "20px" }}>
              <Icon icon={"chevron-right"} />
            </span>
          )}
        </div>
      </button>
    </div>
  )
}

export const MenuItem = ({ label, icon, checked, children }) => {} // eslint-disable-line
export const MenuSeperator = ({ separator = true }) => {} // eslint-disable-line
MenuSeperator.defaultProps = { separator: true }
