import PropTypes from "prop-types"
import React from "react"

const styles = {
  caption: {
    fontSize: "8px",
    float: "left"
  },
  colorLegende: {
    width: "100%",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    height: "25px",
    //marginBottom: "5px",
    clear: "none",
    backgroundColor: "#f9f9f9",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center"
  },
  color: {
    display: "inline-block",
    marginLeft: "10px",
    padding: "4px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "15%",
    textAlign: "center",
    fontSize: "10px",
    color: "#333",
    flex: "1"
  }
}

export const ColorLegende = ({ caption, children }) => {
  let captionEl = null
  if (caption) {
    captionEl = <div css={styles.caption}>{caption}</div>
  }
  return (
    <div css={styles.colorLegende}>
      {captionEl}
      {children}
    </div>
  )
}

export default ColorLegende

export const Color = ({ children, className }) => {
  return (
    <div css={styles.color} className={className}>
      {children}
    </div>
  )
}
