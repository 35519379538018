import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "zustand/middleware/immer"
import { devtools } from "zustand/middleware"
import request from "superagent"
import to from "await-to-js"
import usePermissionStore from "app/permissions/state"
import useMenuStore from "../menu/state"
import useSperrDatumStore from "../sperrdatum/state"

const emptyUser = {
  user_name: null,
  new_password_required: false,
  logged_in: "UNKNOWN"
}

const loggedOfUser = {
  user_name: null,
  new_password_required: false,
  logged_in: false
}

export const useAuthStore = create(
  immer((set, get) => ({
    user: emptyUser,
    error: null,
    isFetching: false,
    actions: {
      CheckLogin: async () => {
        const [err, resp] = await to(request.get("/api/auth"))
        if (err) {
          get().actions.SetError(err)
          return
        }
        get().actions.SetUser(resp.body)
      },
      InitUser: async (user) => {
        const [err, resp] = await to(
          request
            .post("/api/auth/init")
            .send({ user_name: user.user_name, user_pass: user.password })
        )
        if (err) {
          get().actions.SetError(err)
          return
        }
        get().actions.SetUser(resp.body)
      },
      Login: async (user) => {
        const [err, resp] = await to(
          request
            .post("/api/auth")
            .send({ user_name: user.user_name, user_pass: user.password })
        )
        if (err) {
          get().actions.SetError(err.response.body)
          return
        }
        if (resp.code === 400) {
          get().actions.SetError(resp.body)
          return
        }
        get().actions.SetUser(resp.body)
      },
      ChangeUserPassword: async (user, current_password, new_password) => {
        const [err, resp] = await to(
          request.post("/api/auth/password").send({
            user_name: user,
            user_pass: current_password,
            new_pass: new_password
          })
        )
        if (err) {
          get().actions.SetError(err)
          throw err
        }
        set((state) => {
          state.error = null
        })
        get().actions.SetUser(resp.body)
        return true
      },
      ChangePassword: async (user, current_password, new_password) => {
        const [err, resp] = await to(
          request.post("/api/auth/cpassword").send({
            user_name: user,
            user_pass: current_password,
            new_pass: new_password
          })
        )
        if (err) {
          get().actions.SetError(err)
          return
        }
        get().actions.SetUser(resp.body)
      },
      Logout: async () => {
        //request.delete("/api/auth")
        const [err, _resp] = await to(request.delete("/api/auth"))
        if (err) {
          get().actions.SetError(err)
          return
        }
        get().actions.SetUser(loggedOfUser)
        usePermissionStore.getState().actions.reset()
        useMenuStore.getState().actions.Reset()
        useSperrDatumStore.getState().actions.Reset()
      },
      SetUser: (user) => {
        set((state) => {
          state.user = user
        })
      },
      SetError: (err) => {
        set((state) => {
          state.error = err.message
          state.allow_reset = true
        })
      }
    }
  }))
)

export default useAuthStore
