import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "zustand/middleware/immer"
import { devtools } from "zustand/middleware"
import request from "superagent"
import to from "await-to-js"

const useArionModeStore = create(
  immer(
    devtools((set, get) => ({
      arionMode: null,
      actions: {
        loadArionMode: async () => {
          const [err, resp] = await to(request.get("/api/arionmode"))
          if (err) {
            console.error(err)
            return
          }
          set((state) => {
            state.arionMode = resp.body.arion
          })
        },
        setArionMode: async (arionMode) => {
          const [err, resp] = await to(
            request.post("/api/arionmode").send({ arion: arionMode })
          )
          if (err) {
            console.error(err)
            return
          }
          set((state) => {
            state.arionMode = arionMode
          })
        }
      }
    }))
  )
)

export const useIsArionMode = () => {
  return useArionModeStore((state) => state.arionMode === true)
}

export default useArionModeStore
