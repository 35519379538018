import { Dialog } from "primereact/dialog"

export const Modal = ({
  open = false,
  header,
  footer,
  children,
  onClose,
  style,
  maximizable,
  ...rest
}) => {
  return (
    <Dialog
      maximizable={maximizable}
      visible={open}
      header={header}
      footer={footer}
      onHide={onClose}
      style={style}
      {...rest}
    >
      {open ? children : null}
    </Dialog>
  )
}

export default Modal
