import { Tooltip as PToolTip } from "primereact/tooltip"
import { forwardRef, Fragment, useRef } from "react"
import PropTypes from "prop-types"

export const Tooltip = forwardRef(
  ({ title, children, noWrapper, ...rest }, ref) => {
    const localRef = useRef()
    const elementRef = ref || localRef
    let showChildren = children
    if (!ref) {
      showChildren = <span ref={elementRef}>{children}</span>
    }

    return (
      <>
        <PToolTip target={elementRef} showDelay={1000} {...rest}>
          {title}
        </PToolTip>
        {showChildren}
      </>
    )
  }
)

export const ControlledTooltip = forwardRef((props, ref) => {
  return <PToolTip {...props} ref={ref} />
})

export const Tip = ({ showDelay = 1000, ...props }) => (
  <PToolTip {...props} showDelay={showDelay} />
)

export default Tooltip
