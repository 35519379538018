import React from "react"
import { Checkbox as PCheckBox } from "primereact/checkbox"
import { RadioButton } from "primereact/radiobutton"
import { cx } from "@emotion/css"

//export const FormGroup = MUIFormGroup
//export const FormControlLabel = MUIFormControlLabel
export const Checkbox = PCheckBox
export const Radio = RadioButton
//export const RadioGroup = MUIRadioGroup

const styles = {
  label: {
    flex: 1,
    flexBasis: 30,
    textAlign: "right",
    paddingRight: "10px",
    whiteSpace: "nowrap",
    fontSize: "10px",
    color: "#444",
    alignSelf: "center"
  },
  labelAlign: {
    ["left"]: { textAlign: "left" }
  },
  labelFixed: {
    flexShrink: 0,
    flexGrow: 0
  },
  control: {
    flex: 1,
    flexBasis: 70,
    alignSelf: "center",
    "&:focus-visible": {
      outline: "none"
    }
  },
  controlGroup: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    marginTop: "2px"
  }
}

export const Label = ({ children, help, label, className, containerStyle }) => {
  return (
    <div style={containerStyle} className="flex flex-column gap-0">
      <label
        css={{ fontSize: "0.9rem", color: "#666" }}
        className={cx("flex flex-column gap-0", className)}
      >
        {label}
        {children}
      </label>
      {help && (
        <small css={{ fontSize: "0.8rem", color: "#666" }}>{help}</small>
      )}
    </div>
  )
}

export const VLabel = ({
  children,
  help,
  label,
  className,
  id,
  reverse = false
}) => {
  return (
    <div
      key={id}
      className={cx("flex align-items-center", className)}
      css={{ margin: "4px", height: "14px" }}
    >
      {!reverse && children}
      <label
        htmlFor={id}
        className={reverse ? "mr-2" : "ml-2"}
        css={{ fontSize: "0.875rem", color: "#666" }}
      >
        {label}
      </label>
      {reverse && children}
    </div>
  )
}

export const AlignedForm = ({ children, ...props }) => {
  return <div>{children}</div>
}

export const Form = ({ children, ...props }) => {
  return <div>{children}</div>
}

export default Form

export const ControlGroup = ({ children, ...props }) => {
  return <div css={styles.controlGroup}>{children}</div>
}

export const Control = ({ children, width, fixed, ...props }) => {
  width = width || 70
  return (
    <div
      css={[styles.control, fixed && styles.labelFixed, { flexBasis: width }]}
      {...props}
    >
      {children}
    </div>
  )
}
export const Controls = ({ children, props }) => {
  return <div {...props}>{children}</div>
}
